/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthSymptomFragmentFragmentDoc } from '../../fragments/__generated__/healthSymptomData';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthSymptomSpecializationsCreateMutationVariables = Types.Exact<{
  healthSymptomSpecializationsCreateId: Types.Scalars['ID'];
  specializations: Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>;
}>;


export type HealthSymptomSpecializationsCreateMutation = { __typename?: 'Mutation', healthSymptomSpecializationsCreate?: { __typename?: 'HealthSymptomSpecializationsCreate', healthSymptom?: { __typename?: 'HealthSymptom', id: string, name: string, created: any, specializations?: Array<{ __typename?: 'HealthSymptomSpecialization', id: string, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null> | null } | null, specializationErrors: Array<{ __typename?: 'SpecializationError', code: Types.SpecializationErrorCode, field?: string | null, message?: string | null }> } | null };


export const HealthSymptomSpecializationsCreateDocument = gql`
    mutation HealthSymptomSpecializationsCreate($healthSymptomSpecializationsCreateId: ID!, $specializations: [String]!) {
  healthSymptomSpecializationsCreate(
    id: $healthSymptomSpecializationsCreateId
    specializations: $specializations
  ) {
    healthSymptom {
      ...HealthSymptomFragment
    }
    specializationErrors {
      code
      field
      message
    }
  }
}
    ${HealthSymptomFragmentFragmentDoc}`;
export type HealthSymptomSpecializationsCreateMutationFn = Apollo.MutationFunction<HealthSymptomSpecializationsCreateMutation, HealthSymptomSpecializationsCreateMutationVariables>;

/**
 * __useHealthSymptomSpecializationsCreateMutation__
 *
 * To run a mutation, you first call `useHealthSymptomSpecializationsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthSymptomSpecializationsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthSymptomSpecializationsCreateMutation, { data, loading, error }] = useHealthSymptomSpecializationsCreateMutation({
 *   variables: {
 *      healthSymptomSpecializationsCreateId: // value for 'healthSymptomSpecializationsCreateId'
 *      specializations: // value for 'specializations'
 *   },
 * });
 */
export function useHealthSymptomSpecializationsCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthSymptomSpecializationsCreateMutation, HealthSymptomSpecializationsCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthSymptomSpecializationsCreateMutation, HealthSymptomSpecializationsCreateMutationVariables>(HealthSymptomSpecializationsCreateDocument, options);
      }
export type HealthSymptomSpecializationsCreateMutationHookResult = ReturnType<typeof useHealthSymptomSpecializationsCreateMutation>;
export type HealthSymptomSpecializationsCreateMutationResult = Apollo.MutationResult<HealthSymptomSpecializationsCreateMutation>;
export type HealthSymptomSpecializationsCreateMutationOptions = Apollo.BaseMutationOptions<HealthSymptomSpecializationsCreateMutation, HealthSymptomSpecializationsCreateMutationVariables>;
/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthParameterListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthParameterFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthParameterSortingInput>;
}>;


export type HealthParameterListQuery = { __typename?: 'Query', getHealthParameters?: { __typename?: 'HealthParameterConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } | null, edges?: Array<{ __typename?: 'HealthParameterEdge', node?: { __typename?: 'HealthParameter', id?: string | null, code?: string | null, standardCodeDisplay?: string | null, display?: string | null, arabicDisplay?: string | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, referenceRange?: string | null, defaultValue?: string | null, minNormalRangeValue?: number | null, maxNormalRangeValue?: number | null, allowedValues?: Array<string | null> | null, createdDate?: string | null, category?: { __typename?: 'FieldCategory', display?: string | null, arabicDisplay?: string | null } | null, unit?: { __typename?: 'FieldUnit', display?: string | null, arabicDisplay?: string | null } | null } | null } | null> | null } | null };


export const HealthParameterListDocument = gql`
    query HealthParameterList($first: Int, $last: Int, $before: String, $after: String, $filter: HealthParameterFilterInput, $sortBy: HealthParameterSortingInput) {
  getHealthParameters(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        code
        standardCodeDisplay
        display
        arabicDisplay
        category {
          display
          arabicDisplay
        }
        type
        chartType
        unit {
          display
          arabicDisplay
        }
        referenceRange
        type
        defaultValue
        minNormalRangeValue
        maxNormalRangeValue
        allowedValues
        createdDate
      }
    }
  }
}
    `;

/**
 * __useHealthParameterListQuery__
 *
 * To run a query within a React component, call `useHealthParameterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthParameterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthParameterListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthParameterListQuery(baseOptions?: Apollo.QueryHookOptions<HealthParameterListQuery, HealthParameterListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthParameterListQuery, HealthParameterListQueryVariables>(HealthParameterListDocument, options);
      }
export function useHealthParameterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthParameterListQuery, HealthParameterListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthParameterListQuery, HealthParameterListQueryVariables>(HealthParameterListDocument, options);
        }
export type HealthParameterListQueryHookResult = ReturnType<typeof useHealthParameterListQuery>;
export type HealthParameterListLazyQueryHookResult = ReturnType<typeof useHealthParameterListLazyQuery>;
export type HealthParameterListQueryResult = Apollo.QueryResult<HealthParameterListQuery, HealthParameterListQueryVariables>;
import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { IGuidedCareTemplateActivityItem } from "pages/Templates/types";
import { GuidedCareTemplateActivityItemsManage } from "../../components/TemplateActivityItemsManage/GuidedCareTemplateActivityItemsManage";
import { GuidedCareTemplateActivityServicesManage } from "../../components/TemplateActivityServicesManage/GuidedCareTemplateActivityServicesManage";

export const useGuidedCareTemplateActivitiesColumns = (): CustomTableColumnProps<IGuidedCareTemplateActivityItem>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "type",
        header: t("Type"),
        accessor: ({ templateActivityType }) => t(templateActivityType?.label!),
      },
      {
        key: "position",
        header: t("Position"),
        accessor: ({ teamMember }) => t(teamMember?.label!),
      },
      {
        key: "numberOfOccurrences",
        header: t("Occurrences"),
        accessor: ({ numberOfOccurrences, frequencyType }) => `${numberOfOccurrences} / ${t(frequencyType?.label!)}`,
      },
      {
        key: "serviceCode",
        header: t("Services"),
        accessor: ({ templateActivityType }, index) => (
          <GuidedCareTemplateActivityServicesManage activityType={templateActivityType?.value} activityIndex={index} />
        ),
      },
      {
        key: "items",
        header: t("Items"),
        accessor: ({ activityId }) => <GuidedCareTemplateActivityItemsManage activityId={activityId} />,
      },
    ];
  }, [t]);
};

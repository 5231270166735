import { FileDownload, FileImportDialog } from "@health/domains";
import { BulkTemplateCode } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { useToggle } from "shared/hooks/useToggle";
import { healthParametersPaths } from "../../constants";

export const HealthParameterListPageWrapper = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { open, handleToggle } = useToggle();
  const { customTableProps } = useGridContext();

  const onAddNewItemClick = () => {
    navigate(healthParametersPaths.create.fullPath);
  };

  const handleSuccessUpload = () => {
    customTableProps?.onUpdateGrid && customTableProps?.onUpdateGrid();
  };

  return (
    <PageWrapper
      filters={<FilterGrid />}
      actions={
        <>
          <TableSettingComponent />

          <MainListActions hasAddNew onAddNewItem={onAddNewItemClick}>
            <FileImportDialog
              title={t("Import Health Parameters")}
              subTitle={t("Upload Health Parameters you want to get")}
              isOpen={open}
              onToggle={handleToggle}
              onSuccessUpload={handleSuccessUpload}
              templateCode={BulkTemplateCode.HealthParameter}
            />

            <FileDownload templateCode={BulkTemplateCode.HealthParameter} />
          </MainListActions>
        </>
      }
    >
      <TableGrid />
    </PageWrapper>
  );
};

import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, ShowButton, useFormatDate } from "@toolkit/ui";
import { getHealthConditionsAutocompleteFilter } from "@health/autocompletes";
import { getGuidedCareTypeFilter, getYesNoAutocompleteFilter, guidedCareTypeOptionsMap } from "@health/enum-options";
import { H_OrderDirection, HealthProgramTemplate, HealthProgramTemplateSortingField } from "@health/queries/types";
import { guidedCareTemplatesPaths } from "pages/Templates/constants";
import { GuidedCareTemplateActivation } from "../../components/TemplateActivation/GuidedCareTemplateActivation";

export const useGuidedCareTemplateListColumns = (): CustomTableColumnProps<HealthProgramTemplate>[] => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { formatLocalizedDate } = useFormatDate();

  const onShowActivityItemsClick = useCallback(
    (templateId: string) => {
      return () => {
        navigate(guidedCareTemplatesPaths.activitiesItemList.fullPathWithParams({ templateId }));
      };
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Template Name"),
        accessor: "name",
        type: "string",
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramTemplateSortingField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "type",
        header: t("Template Type"),
        accessor: ({ guidedCareType }) => guidedCareTypeOptionsMap[guidedCareType!]?.label,
        type: "string",
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramTemplateSortingField.GuidedCareType,
        filter: getGuidedCareTypeFilter({ name: "guidedCareType" }),
      },
      {
        key: "healthCondition",
        header: t("Health Condition"),
        showOnlyForFilterField: true,
        filter: getHealthConditionsAutocompleteFilter({ name: "healthConditionId" }),
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatLocalizedDate(createdDate),
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramTemplateSortingField.Created,
      },
      {
        key: "createdDateFrom",
        header: t("Created Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "creationDate.gte",
        },
      },
      {
        key: "createdDateTo",
        header: t("Created Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "creationDate.lte",
        },
      },
      {
        key: "lastModifiedDate",
        header: t("Last Modified Date"),
        accessor: ({ lastModifiedDate }) => formatLocalizedDate(lastModifiedDate),
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramTemplateSortingField.Modified,
      },
      {
        key: "activityItems",
        header: t("Activity Items"),
        accessor: ({ id }) => <ShowButton onClick={onShowActivityItemsClick(id)} />,
      },
      {
        key: "version",
        header: t("Version"),
        type: "string",
        accessor: "version",
      },
      {
        key: "published",
        header: t("Published"),
        accessor: ({ isDraft }) => (isDraft ? t("No") : t("Yes")),
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramTemplateSortingField.IsDraft,
        filter: getYesNoAutocompleteFilter({ name: "isDraft", reverse: true }),
      },
      {
        key: "isVerified",
        header: t("Verified"),
        showOnlyForFilterField: true,
        filter: getYesNoAutocompleteFilter({ name: "isVerified" }),
      },
      {
        key: "active",
        header: t("Active"),
        accessor: row => <GuidedCareTemplateActivation id={row.id} isActive={!!row.active} />,
        filter: getYesNoAutocompleteFilter({ name: "isActive" }),
      },
    ];
  }, [formatLocalizedDate, onShowActivityItemsClick, t]);
};

import { replacePathParams } from "utils";

export const healthSymptomsRoute = `/health-symptoms`;
const healthSymptomsNewRoute = "new";
const healthSymptomsEditRoute = "edit/:id";

export const healthSymptomsPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${healthSymptomsRoute}${this.route}`;
    },
  },
  newPath: {
    route: healthSymptomsNewRoute,
    get fullPath() {
      return `${healthSymptomsRoute}/${this.route}`;
    },
  },
  editPath: {
    route: healthSymptomsEditRoute,
    get fullPath() {
      return `${healthSymptomsRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};

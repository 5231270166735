import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useModal } from "@toolkit/ui";
import { useHealthSymptomSpecializationDeleteMutation } from "pages/HealthSymptoms/gql";

export const useHealthSymptomSpecializationsDelete = () => {
  const { t } = useTranslation("gcadmin");
  const { failed, succeeded } = useAddToast();
  const { open, handleClose, handleOpen } = useModal();

  const [fetchHealthSymptomSpecializationDelete, { loading }] = useHealthSymptomSpecializationDeleteMutation({
    onCompleted: request => {
      const errors = request?.healthSymptomSpecializationDelete?.specializationErrors || [];
      if (errors?.length > 0) {
        handleClose();
        failed(t(formatMessageErrors(errors)));
      } else {
        handleClose();
        succeeded(t("Health Symptoms Specialization Deleted successfully"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleHealthSymptomSpecializationDelete = (id: string) => () => {
    fetchHealthSymptomSpecializationDelete({
      variables: {
        healthSymptomSpecializationDeleteId: id,
      },
    });
  };

  return {
    open,
    loading,
    handleClose,
    handleOpen,
    handleHealthSymptomSpecializationDelete,
  };
};

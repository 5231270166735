import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { GridProvider } from "@health/domains";
import { MedicalForm, MedicalFormSortingField, SortDirection } from "@health/queries/types";
import { createMedicalFormsBreadcrumbs, medicalFormsPaths } from "pages/MedicalForms/constants";
import { useAdminMedicalFormListQuery } from "pages/MedicalForms/gql";
import { MedicalFormListPageWrapper } from "../../components/MedicalFormListPageWrapper/MedicalFormListPageWrapper";
import { useAdminMedicalFormsTableColumns } from "./useMedicalFormsTableColumns";

export const AdminMedicalFormsContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const onEditRowClick = (item: MedicalForm) => {
    navigate(medicalFormsPaths.update.fullPathWithParams({ medicalFormId: item.id! }));
  };

  useEffect(() => {
    setBreadCrumb({ title: createMedicalFormsBreadcrumbs(t).title });
  }, [setBreadCrumb, t]);

  return (
    <GridProvider
      gridName={"gcAdminMedicalFormList"}
      columns={useAdminMedicalFormsTableColumns()}
      query={useAdminMedicalFormListQuery}
      dataAccessor={"medicalForms"}
      hasTableSetting
      filterInput={{ isAdminForm: true }}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: onEditRowClick,
      }}
      variables={{
        sortBy: {
          direction: SortDirection.Desc,
          field: MedicalFormSortingField.CreatedDate,
        },
      }}
    >
      <MedicalFormListPageWrapper />
    </GridProvider>
  );
};

/* eslint-disable react/prop-types */
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Grid, MDTextEditor, ShowButton } from "@toolkit/ui";
import { ArticlesQueryNode } from "../types";

export const ArticlePreview: React.FC<ArticlesQueryNode> = props => {
  const { t } = useTranslation("gcadmin");
  return (
    <CustomDialog type='info' button={<ShowButton />} title={props?.title || t("Article's Data")}>
      <Grid container>
        <Grid item xs={12}>
          <MDTextEditor label={t("Description")} value={props?.description!} readonly />
        </Grid>
        <Grid item xs={12}>
          <MDTextEditor label={t("Content")} value={props?.content!} readonly />
        </Grid>
        <Grid item xs={12}>
          <MDTextEditor label={t("Content (Arabic)")} value={props?.contentAr!} readonly direction='rtl' />
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthSymptomFragmentFragmentDoc } from '../../fragments/__generated__/healthSymptomData';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthSymptomQueryVariables = Types.Exact<{
  healthSymptomId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type HealthSymptomQuery = { __typename?: 'Query', healthSymptom?: { __typename?: 'HealthSymptom', id: string, name: string, created: any, specializations?: Array<{ __typename?: 'HealthSymptomSpecialization', id: string, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null> | null } | null };


export const HealthSymptomDocument = gql`
    query HealthSymptom($healthSymptomId: ID) {
  healthSymptom(id: $healthSymptomId) {
    ...HealthSymptomFragment
  }
}
    ${HealthSymptomFragmentFragmentDoc}`;

/**
 * __useHealthSymptomQuery__
 *
 * To run a query within a React component, call `useHealthSymptomQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthSymptomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthSymptomQuery({
 *   variables: {
 *      healthSymptomId: // value for 'healthSymptomId'
 *   },
 * });
 */
export function useHealthSymptomQuery(baseOptions?: Apollo.QueryHookOptions<HealthSymptomQuery, HealthSymptomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthSymptomQuery, HealthSymptomQueryVariables>(HealthSymptomDocument, options);
      }
export function useHealthSymptomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthSymptomQuery, HealthSymptomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthSymptomQuery, HealthSymptomQueryVariables>(HealthSymptomDocument, options);
        }
export type HealthSymptomQueryHookResult = ReturnType<typeof useHealthSymptomQuery>;
export type HealthSymptomLazyQueryHookResult = ReturnType<typeof useHealthSymptomLazyQuery>;
export type HealthSymptomQueryResult = Apollo.QueryResult<HealthSymptomQuery, HealthSymptomQueryVariables>;
import { ApolloProvider } from "@apollo/client";
import { getEnvVariable } from "@health/env";
import { SadaProvider } from "@health/sada";
import { I18nextProvider, i18n } from "@toolkit/i18n";
import { ToolkitUiProvider } from "@toolkit/ui";
import React, { FC, PropsWithChildren, Suspense, useMemo } from "react";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { BrowserRouter } from "react-router-dom";
import { client } from "shared/configs/apollo";
import { oidcUserManager } from "shared/configs/oidc";
import { useOidcUserProfile } from "shared/hooks";
import { ErrorBoundary } from "./ErrorBoundary";
import { RootRouter } from "./shared/components/Root/RootRoutes";

window["getAuthLocalStorageKey"] = () =>
  `oidc.user:${getEnvVariable("KEYCLOAK_REALM_LINK", process.env.REACT_APP_KEYCLOAK_REALM_LINK)}:${getEnvVariable(
    "KEYCLOAK_CLIENT_ID_GUIDED_CARE",
    process.env.REACT_APP_KEYCLOAK_CLIENT_ID_GUIDED_CARE || "admin"
  )}`;

const AppRootProviders: FC<PropsWithChildren> = ({ children }) => {
  const { accessToken: token } = useOidcUserProfile();

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <ToolkitUiProvider>
              <Suspense fallback={<div>Loading...</div>}>
                <SadaProvider token={token || ""}>{children}</SadaProvider>
              </Suspense>
            </ToolkitUiProvider>
          </BrowserRouter>
        </I18nextProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

function App(): React.ReactElement {
  const oidcConfig: AuthProviderProps = useMemo(
    () =>
      ({
        ...oidcUserManager.settings,
        onSigninCallback: user => {
          if (!user || !user?.access_token) return;
          oidcUserManager.storeUser(user);
        },
      } as AuthProviderProps),
    []
  );

  return (
    <AuthProvider {...oidcConfig}>
      <AppRootProviders>
        <RootRouter />
      </AppRootProviders>
    </AuthProvider>
  );
}

export default App;

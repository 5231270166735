import { z } from "zod";
import { zodSchema } from "@health/domains";
import { TemplateFieldType } from "@health/queries/types/types";
import { i18n } from "@toolkit/i18n";
import { isNil } from "lodash";
import { createZodAutocompleteObject } from "@toolkit/ui";

export const healthParameterUpsertFormSchema = z
  .object({
    code: z.string().min(1).max(255),
    display: z.string().min(1).max(255),
    arabicDisplay: z.string().min(1).max(255),
    category: zodSchema.healthParameterCategoryAutocomplete,
    unit: zodSchema.healthParameterUnitAutocompleteOptional,
    type: zodSchema.templateFieldTypeAutocompleteEnum,
    chartType: zodSchema.chartTypeAutocompleteEnum,
    standard: zodSchema.fieldStandardAutocompleteEnum,
    standardCode: zodSchema.systemCodeAutocomplete,
    allowedValues: z.array(z.string()),
    defaultValue: createZodAutocompleteObject().nullable().optional(),
    referenceRange: z.string().nullable().optional(),
    minNormalRangeValue: z.number().nullable().optional(),
    maxNormalRangeValue: z.number().nullable().optional(),
    isMandatory: z.boolean(),
    isVisibleForPatient: z.boolean(),
  })
  .superRefine((data, ctx) => {
    if (data.type?.value === TemplateFieldType.String) {
      if (data.allowedValues.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "gcadmin" }),
          path: ["allowedValues"],
        });
      }

      if (!data.defaultValue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "gcadmin" }),
          path: ["defaultValue"],
        });
      }
    } else if (data.type?.value === TemplateFieldType.Number) {
      if (isNil(data.minNormalRangeValue)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "gcadmin" }),
          path: ["minNormalRangeValue"],
        });
      }

      if (isNil(data.maxNormalRangeValue)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "gcadmin" }),
          path: ["maxNormalRangeValue"],
        });
      }
    }
  });

export type IHealthParameterUpsertFormValues = z.infer<typeof healthParameterUpsertFormSchema>;

export const healthParameterUpsertFormDefaultValues: Partial<IHealthParameterUpsertFormValues> = {
  code: undefined,
  display: undefined,
  arabicDisplay: undefined,
  category: undefined,
  unit: undefined,
  type: undefined,
  chartType: undefined,
  standard: undefined,
  standardCode: undefined,
  allowedValues: [],
  defaultValue: undefined,
  referenceRange: undefined,
  minNormalRangeValue: undefined,
  maxNormalRangeValue: undefined,
  isMandatory: false,
  isVisibleForPatient: false,
};

import { i18n } from "@toolkit/i18n";
import { articlesCategoriesRoutes } from "pages/KnowledgeBaseArticlesCategories";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components/Root/routeTypes";
import { knowledgeBaseRoute } from "./knowledgeBasePaths";
import { articlesRoutes } from "pages/KnowledgeBaseArticles";
import { MedicationIcon } from "@toolkit/ui";
import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";

export const knowledgeBaseRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }) => {
  return {
    id: "knowledge-base",
    text: i18n.t("Knowledge Base", { ns: "gcadmin" }),
    icon: <MedicationIcon />,
    isProhibited: !hasPermission(PermissionEnum.ManageArticles),
    route: knowledgeBaseRoute,
    subItems: [articlesCategoriesRoutes({ navigate }), articlesRoutes({ navigate })],
  };
};

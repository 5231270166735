import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { articlesCategoriesBreadcrumb } from "../../constants";
import { articlesCategoriesPaths } from "../../constants";
import { ArticleCategoryUpsertForm, IArticleCategoryUpsertFormValues } from "../../forms";
import { ArticleCategoryUpdateMutation, useArticleCategoryUpdateMutation } from "../../gql";
import { useArticleCategoryQuery } from "../../gql";
import { IArticleCategoryUpdateContainerParams } from "../../types";
import { convertArticleCategoryFormValuesToBackEndValues } from "../../utils";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ArticleCategoryUpdateContainer: React.FC = () => {
  const { t } = useTranslation("gcadmin");
  const { articleCategoryId } = useParams<IArticleCategoryUpdateContainerParams>();

  const { data, loading } = useArticleCategoryQuery({
    variables: { articleCategoryId: articleCategoryId! },
    skip: !articleCategoryId,
  });
  const articleCategory = data?.articleCategory;

  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchArticleCategoryUpdateMutation, { loading: isSubmitting }] = useArticleCategoryUpdateMutation({
    onCompleted: (data: ArticleCategoryUpdateMutation) => {
      if (data?.articleCategoryUpdate?.id) {
        succeeded(t("ArticleCategory updated successfully!"));
        navigate(articlesCategoriesPaths.main.fullPath);
      } else {
        failed(t("ArticleCategory update failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onArticleCategoryUpsertFormChange = (values: IArticleCategoryUpsertFormValues) => {
    const _values = convertArticleCategoryFormValuesToBackEndValues(values);

    fetchArticleCategoryUpdateMutation({
      variables: {
        articleCategoryUpdateId: articleCategoryId!,
        input: _values,
      },
    });
  };
  useEffect(() => {
    const { title, main, update } = articlesCategoriesBreadcrumb();
    setBreadCrumb({ title, values: [main, update(pickLocalizedValue(articleCategory?.display!, articleCategory?.displayAr!))] });
  }, [setBreadCrumb, articleCategory, t]);
  return (
    <ArticleCategoryUpsertForm
      isLoading={loading}
      articleCategory={articleCategory!}
      isSubmitting={isSubmitting}
      submitButtonLabel={t("Update")}
      onChange={onArticleCategoryUpsertFormChange}
    />
  );
};

import { makeStyles } from "@toolkit/ui";

export const useGuidedCareTemplateActivitiesItemsTimelineMenuStyle = makeStyles()(theme => ({
  btn: {
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    boxShadow: theme.mixins.shadows.md,
  },
  paper: {
    minWidth: 120,
    borderRadius: "10px !important",
  },
}));

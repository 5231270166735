import { FormProvider, UseFormReturn } from "react-hook-form";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useCustomForm } from "@toolkit/core";
import { FormAutocomplete, FormNumberField, FormTextField, Grid } from "@toolkit/ui";
import {
  guidedCareTemplateInterventionUpsertFormSchema,
  IGuidedCareTemplateInterventionUpsertFormValues,
  guidedCareTemplateInterventionUpsertFormDefaultValues,
} from "./GuidedCareTemplateInterventionUpsertFormSchema";
import { uniqueId } from "lodash";
import { guidedCareTemplateInterventionCategoryOptions } from "@health/enum-options";

export type IGuidedCareTemplateInterventionUpsertFormEvent = {
  type: "CREATE" | "UPDATE";
  payload: {
    values: IGuidedCareTemplateInterventionUpsertFormValues;
  };
};

type GuidedCareTemplateInterventionUpsertFormProps = {
  intervention?: IGuidedCareTemplateInterventionUpsertFormValues;
  onChange: (event: IGuidedCareTemplateInterventionUpsertFormEvent) => void;
};

export type GuidedCareTemplateInterventionUpsertFormRef = {
  getForm: () => UseFormReturn<IGuidedCareTemplateInterventionUpsertFormValues>;
  submit: () => void;
};

const GuidedCareTemplateInterventionUpsertFormForwardRef: ForwardRefRenderFunction<
  GuidedCareTemplateInterventionUpsertFormRef,
  GuidedCareTemplateInterventionUpsertFormProps
> = (props, ref) => {
  const { intervention, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IGuidedCareTemplateInterventionUpsertFormValues>({
    defaultValues: guidedCareTemplateInterventionUpsertFormDefaultValues,
    schema: guidedCareTemplateInterventionUpsertFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IGuidedCareTemplateInterventionUpsertFormValues) => {
    if (intervention) {
      onChange({
        type: "UPDATE",
        payload: {
          values,
        },
      });
    } else {
      onChange({
        type: "CREATE",
        payload: {
          values: {
            ...values,
            uniqueId: uniqueId("intervention_"),
          },
        },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (intervention) {
      setValues(intervention);
    }
  }, [intervention, setValues]);

  return (
    <FormProvider {...form}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
        </Grid>

        <Grid item xs={6}>
          <FormTextField name={"nameAr"} label={t("Name (Arabic)")} placeholder={t("Name (Arabic)")} />
        </Grid>

        <Grid item xs={6}>
          <FormTextField name={"code"} label={t("Code")} placeholder={t("Code")} />
        </Grid>

        <Grid item xs={6}>
          <FormNumberField name={"duration"} label={t("Duration")} placeholder={t("Duration")} />
        </Grid>

        <Grid item xs={12}>
          <FormAutocomplete name={"category"} label={t("Category")} options={guidedCareTemplateInterventionCategoryOptions} />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormTextField name={"description"} label={t("Description")} placeholder={t("Description")} multiline rows={3} />
            </Grid>

            <Grid item xs={6}>
              <FormTextField
                name={"descriptionAr"}
                label={t("Description (Arabic)")}
                placeholder={t("Description (Arabic)")}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export const GuidedCareTemplateInterventionUpsertForm = forwardRef(GuidedCareTemplateInterventionUpsertFormForwardRef);

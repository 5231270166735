import { getEnvVariable } from "@health/env";
import { Log, UserManager, WebStorageStateStore } from "oidc-client-ts";
export type UserInfoType = {
  app_role: string;
  app_type: string;
  aud: string;
  doctor_id?: number;
  email_verified: boolean;
  email: string;
  exp: number;
  family_name: string;
  given_name: string;
  meeting_platform_id: string;
  name: string;
  permissions: Array<string>;
  preferred_username: string;
  sub: string;
  user_id: number;
  vendor_id: number;
};
export const oidcUserManager = new UserManager({
  authority: getEnvVariable("KEYCLOAK_REALM_LINK", process.env.REACT_APP_KEYCLOAK_REALM_LINK),
  client_id: getEnvVariable("KEYCLOAK_CLIENT_ID_ADMIN", process.env.REACT_APP_KEYCLOAK_CLIENT_ID_ADMIN || "admin"),
  redirect_uri: location.href,
  response_type: "code",
  scope: "openid profile email",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: false,
  loadUserInfo: true,
  accessTokenExpiringNotificationTimeInSeconds: 10,
  includeIdTokenInSilentSignout: true,
});

oidcUserManager.events.addUserLoaded(user => {
  if (!user || !user?.access_token || user?.expired) {
    oidcUserManager.signinRedirect();
  }
});
process.env.NODE_ENV === "development" && Log.setLogger(console);

/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleCategoryCreateMutationVariables = Types.Exact<{
  input: Types.ArticleCategoryInput;
}>;


export type ArticleCategoryCreateMutation = { __typename?: 'Mutation', articleCategoryCreate?: { __typename?: 'ArticleCategory', code?: string | null, display?: string | null, displayAr?: string | null, id: string } | null };


export const ArticleCategoryCreateDocument = gql`
    mutation ArticleCategoryCreate($input: ArticleCategoryInput!) {
  articleCategoryCreate(input: $input) {
    code
    display
    displayAr
    id
  }
}
    `;
export type ArticleCategoryCreateMutationFn = Apollo.MutationFunction<ArticleCategoryCreateMutation, ArticleCategoryCreateMutationVariables>;

/**
 * __useArticleCategoryCreateMutation__
 *
 * To run a mutation, you first call `useArticleCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleCategoryCreateMutation, { data, loading, error }] = useArticleCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleCategoryCreateMutation(baseOptions?: Apollo.MutationHookOptions<ArticleCategoryCreateMutation, ArticleCategoryCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleCategoryCreateMutation, ArticleCategoryCreateMutationVariables>(ArticleCategoryCreateDocument, options);
      }
export type ArticleCategoryCreateMutationHookResult = ReturnType<typeof useArticleCategoryCreateMutation>;
export type ArticleCategoryCreateMutationResult = Apollo.MutationResult<ArticleCategoryCreateMutation>;
export type ArticleCategoryCreateMutationOptions = Apollo.BaseMutationOptions<ArticleCategoryCreateMutation, ArticleCategoryCreateMutationVariables>;
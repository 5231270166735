import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { IGuidedCareTemplateInterventionActivityUpsertFormValues } from "../TemplateInterventionActivityUpsert/GuidedCareTemplateInterventionActivityUpsertFormSchema";

export const useTemplateGoalRecommendationInterventionsActivitiesFieldsColumns =
  (): CustomTableColumnProps<IGuidedCareTemplateInterventionActivityUpsertFormValues>[] => {
    const { t } = useTranslation("gcadmin");

    return useMemo(() => {
      return [
        {
          key: "type",
          header: t("Type"),
          accessor: ({ templateActivityType }) => templateActivityType?.label,
        },
        {
          key: "position",
          header: t("Position"),
          accessor: ({ teamMember }) => teamMember?.label,
        },
        {
          key: "numberOfOccurrences",
          header: t("Occurrences"),
          accessor: ({ numberOfOccurrences, frequencyType }) => `${numberOfOccurrences} / ${frequencyType?.label}`,
        },
        // todo
        // {
        //   key: "services",
        //   header: t("Services"),
        //   accessor: ({ templateActivityType }, index) => (
        //     <Button
        //       variant={"contained"}
        //       onClick={() => onManageActivityServicesClick(index)}
        //       disabled={
        //         templateActivityType?.value !== GuidedCareActivityType.Lab && templateActivityType?.value !== GuidedCareActivityType.Rad
        //       }
        //     >
        //       {t("Manage")}
        //     </Button>
        //   ),
        // },
      ];
    }, [t]);
  };

/* eslint-disable max-lines */
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components/Root/routeTypes";
import { agentsPaths, agentsRoute } from "./AgentsPaths";
import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { RobotIcon } from "@toolkit/ui";
import { AdminAgentUpdateContainer } from "../containers/AdminAgentUpdateContainer";
import { AdminAgentsList } from "../containers/AdminAgentsListPage";

export const agentsRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }) => {
  return {
    id: "ai-agents",
    text: i18n.t("AI Agents", { ns: "gcadmin" }),
    route: agentsRoute,
    icon: <RobotIcon />,
    hidden: !hasPermission(PermissionEnum.ManageChatFlow),
    isProhibited: !hasPermission(PermissionEnum.ManageChatFlow),
    subItems: [
      {
        id: "ai-agents-list",
        text: i18n.t("Brands", { ns: "gcadmin" }),
        route: agentsPaths.main.route,
        fullPath: agentsPaths.main.fullPath,
        element: <AdminAgentsList />,
        onClick: route => navigate(route),
      },
      {
        id: "ai-agent-update",
        text: i18n.t("Update", { ns: "gcadmin" }),
        element: <AdminAgentUpdateContainer />,
        route: agentsPaths.update.route,
        fullPath: agentsPaths.update.fullPath,
        hidden: true,
        onClick: route => navigate(route),
      },
    ],
  };
};

/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthSymptomFragmentFragmentDoc } from '../../fragments/__generated__/healthSymptomData';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthSymptomsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthSymptomFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthSymptomOrder>;
}>;


export type HealthSymptomsQuery = { __typename?: 'Query', healthSymptoms?: { __typename?: 'HealthSymptomCountableConnection', edges: Array<{ __typename?: 'HealthSymptomCountableEdge', node: { __typename?: 'HealthSymptom', id: string, name: string, created: any, specializations?: Array<{ __typename?: 'HealthSymptomSpecialization', id: string, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null> | null } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const HealthSymptomsDocument = gql`
    query HealthSymptoms($first: Int, $last: Int, $before: String, $after: String, $filter: HealthSymptomFilterInput, $sortBy: HealthSymptomOrder) {
  healthSymptoms(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    edges {
      node {
        ...HealthSymptomFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    ${HealthSymptomFragmentFragmentDoc}`;

/**
 * __useHealthSymptomsQuery__
 *
 * To run a query within a React component, call `useHealthSymptomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthSymptomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthSymptomsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthSymptomsQuery(baseOptions?: Apollo.QueryHookOptions<HealthSymptomsQuery, HealthSymptomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthSymptomsQuery, HealthSymptomsQueryVariables>(HealthSymptomsDocument, options);
      }
export function useHealthSymptomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthSymptomsQuery, HealthSymptomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthSymptomsQuery, HealthSymptomsQueryVariables>(HealthSymptomsDocument, options);
        }
export type HealthSymptomsQueryHookResult = ReturnType<typeof useHealthSymptomsQuery>;
export type HealthSymptomsLazyQueryHookResult = ReturnType<typeof useHealthSymptomsLazyQuery>;
export type HealthSymptomsQueryResult = Apollo.QueryResult<HealthSymptomsQuery, HealthSymptomsQueryVariables>;
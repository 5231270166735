import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, useAddToast, useModal } from "@toolkit/ui";
import {
  GuidedCareTemplateGetDocument,
  GuidedCareTemplatePublishMutation,
  useGuidedCareTemplatePublishMutation,
} from "pages/Templates/gql";
import { FC } from "react";

type GuidedCareTemplatePublishProps = {
  templateCode: string;
};

export const GuidedCareTemplatePublish: FC<GuidedCareTemplatePublishProps> = props => {
  const { templateCode } = props;

  const { t } = useTranslation("gcadmin");
  const { failed, succeeded } = useAddToast();
  const { open, handleOpen, handleClose } = useModal();

  const [fetchGuidedCareTemplatePublishMutation, { loading }] = useGuidedCareTemplatePublishMutation({
    onCompleted: (data: GuidedCareTemplatePublishMutation) => {
      const errors = data?.publishDraftHealthProgramTemplate?.healthProgramTemplateErrors;
      if (errors?.length) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Template has been published successfully"));
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(t(message));
    },
    refetchQueries: [GuidedCareTemplateGetDocument],
  });

  const onPublishClick = () => {
    if (!templateCode) return;
    handleOpen();
  };

  const onWarningConfirmationDialogConfirm = () => {
    fetchGuidedCareTemplatePublishMutation({
      variables: {
        code: templateCode,
      },
    });

    handleClose();
  };

  return (
    <>
      <Button onClick={onPublishClick} disabled={loading}>
        {t("Publish")}
      </Button>
      <CustomDialog
        type='warning'
        text={{
          body: t("Are you sure you want to publish this template?"),
          title: t("Publish Template"),
        }}
        isOpen={open}
        onClose={handleClose}
        onConfirm={onWarningConfirmationDialogConfirm}
      />
    </>
  );
};

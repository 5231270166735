/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthSymptomFragmentFragmentDoc } from '../../fragments/__generated__/healthSymptomData';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthSymptomDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type HealthSymptomDeleteMutation = { __typename?: 'Mutation', healthSymptomDelete?: { __typename?: 'HealthSymptomDelete', healthSymptom?: { __typename?: 'HealthSymptom', id: string, name: string, created: any, specializations?: Array<{ __typename?: 'HealthSymptomSpecialization', id: string, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null> | null } | null, healthSymptomErrors: Array<{ __typename?: 'HealthSymptomError', code: Types.HealthSymptomErrorCode, field?: string | null, message?: string | null }> } | null };


export const HealthSymptomDeleteDocument = gql`
    mutation HealthSymptomDelete($id: ID!) {
  healthSymptomDelete(id: $id) {
    healthSymptom {
      ...HealthSymptomFragment
    }
    healthSymptomErrors {
      code
      field
      message
    }
  }
}
    ${HealthSymptomFragmentFragmentDoc}`;
export type HealthSymptomDeleteMutationFn = Apollo.MutationFunction<HealthSymptomDeleteMutation, HealthSymptomDeleteMutationVariables>;

/**
 * __useHealthSymptomDeleteMutation__
 *
 * To run a mutation, you first call `useHealthSymptomDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthSymptomDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthSymptomDeleteMutation, { data, loading, error }] = useHealthSymptomDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthSymptomDeleteMutation(baseOptions?: Apollo.MutationHookOptions<HealthSymptomDeleteMutation, HealthSymptomDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthSymptomDeleteMutation, HealthSymptomDeleteMutationVariables>(HealthSymptomDeleteDocument, options);
      }
export type HealthSymptomDeleteMutationHookResult = ReturnType<typeof useHealthSymptomDeleteMutation>;
export type HealthSymptomDeleteMutationResult = Apollo.MutationResult<HealthSymptomDeleteMutation>;
export type HealthSymptomDeleteMutationOptions = Apollo.BaseMutationOptions<HealthSymptomDeleteMutation, HealthSymptomDeleteMutationVariables>;
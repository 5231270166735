import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { TeamMemberPosition } from "@health/queries/types";
import { guidedCareTemplatesPaths } from "pages/Templates/constants";
import { GuidedCareTemplateCreateMutation, useGuidedCareTemplateCreateMutation } from "pages/Templates/gql";
import { useGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { convertTemplateFormValuesToBackEndValues } from "pages/Templates/others";
import { IGuidedCareTemplateUpsertFormEvent } from "pages/Templates/types";
import { GuidedCareTemplateUpsertForm } from "../../forms/TemplateUpsert/GuidedCareTemplateUpsertForm";

export const GuidedCareTemplateCreateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { setGuidedCareTemplateCreateContainerBreadcrumb } = useGuidedCareTemplatesBreadcrumbs();

  const [fetchGuidedCareTemplateCreateMutation, { loading: isSubmitting }] = useGuidedCareTemplateCreateMutation({
    onCompleted: (mutationData: GuidedCareTemplateCreateMutation) => {
      const mutationErrors = mutationData?.healthProgramTemplateCreate?.healthProgramTemplateErrors;
      if (!mutationErrors?.length) {
        succeeded(t("Template created successfully"));
        navigate(guidedCareTemplatesPaths.list.fullPath);
      } else {
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });

  const onTemplateUpsertFormChange = (event: IGuidedCareTemplateUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const formValues = event.payload.values;

      const teamLeader = formValues?.teamMembers?.find(item => item?.position?.value === TeamMemberPosition.TeamLeader);

      if (!formValues?.activities?.length) {
        failed(t("Template must have at least one activity"));
        return;
      }

      if (!teamLeader?.specialization) {
        failed(t("Team Leader must have a specialization"));
        return;
      }

      const values = convertTemplateFormValuesToBackEndValues(formValues);

      fetchGuidedCareTemplateCreateMutation({
        variables: {
          input: values,
        },
      });
    }
  };

  useEffect(() => {
    setGuidedCareTemplateCreateContainerBreadcrumb();
  }, [setGuidedCareTemplateCreateContainerBreadcrumb]);

  return (
    <GuidedCareTemplateUpsertForm submitButtonLabel={t("Create")} isButtonDisabled={isSubmitting} onChange={onTemplateUpsertFormChange} />
  );
};

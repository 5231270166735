import { useTranslation, pickLocalizedValue } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { ArticleCategoriesQueryNode } from "../../types";

export const useArticleCategoriesListTableColumns = (): CustomTableColumnProps<ArticleCategoriesQueryNode>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("ID"),
        accessor: "id",
        filter: {
          type: "string",
          name: "ids",
        },
      },
      {
        key: "display",
        header: t("Display"),
        accessor: pickLocalizedValue("display", "displayAr"),
        filter: {
          type: "string",
          name: "display",
        },
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        filter: {
          type: "string",
          name: "code",
        },
      },
    ];
  }, []);
};

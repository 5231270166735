import { useTranslation } from "@toolkit/i18n";
import { Grid, MDEditorField } from "@toolkit/ui";
import { ArticleQueryData } from "pages/KnowledgeBaseArticles/types";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

type ArticleFormContentsProps = {
  article?: ArticleQueryData;
};

export const ArticleFormContents: FC<ArticleFormContentsProps> = ({ article }) => {
  const { control } = useFormContext();
  const { accessToken } = useOidcUserProfile();

  const { t } = useTranslation("provider");
  return (
    <>
      <Grid item xs={12}>
        <MDEditorField
          controllerProps={{
            name: "content",
            control: control,
          }}
          label={t("Content")}
          placeholder={t("Content")}
          defaultValue={article?.content || ""}
          token={accessToken}
        />
      </Grid>
      <Grid item xs={12}>
        <MDEditorField
          controllerProps={{
            name: "contentAr",
            control: control,
          }}
          label={t("Content (Arabic)")}
          placeholder={t("Content (Arabic)")}
          defaultValue={article?.contentAr || ""}
          token={accessToken}
          direction='rtl'
        />
      </Grid>
    </>
  );
};

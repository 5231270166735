import { SystemCodeAutocomplete } from "@health/autocompletes";
import { CodeSystemCode, HealthSymptom } from "@health/queries/types";
import { formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Chip, createAutocompleteOption, Grid, TruncateTypography } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useHealthSymptomSpecializationsDelete } from "../../list/components/useHealthSymptomSpecializationsDelete";

export const HealthSymptomSpecializations: FC<{ defaultValues?: HealthSymptom }> = ({ defaultValues }) => {
  const { t } = useTranslation("gcadmin");

  const form = useFormContext();
  const { register, setValue } = form;

  const params = useParams();

  const { handleHealthSymptomSpecializationDelete } = useHealthSymptomSpecializationsDelete();

  const handleDelete = (specializationId: string) => {
    const addedSpecializationId = defaultValues?.specializations?.find(item => item?.id === specializationId);
    params?.id && addedSpecializationId && handleHealthSymptomSpecializationDelete(specializationId)();
  };

  // Note: This is temporary and will be removed when we convert the form to use Zod schema.
  useEffect(() => {
    register("specializations", {
      required: getRequiredValidation(t, true),
    });
  }, [register, t]);

  // Note: This is temporary and will be removed when we use RHF setValue method to update all fields at once.
  useEffect(() => {
    const specializations = defaultValues?.specializations?.length
      ? defaultValues?.specializations?.map(specialty =>
          createAutocompleteOption(
            {
              id: specialty?.id!,
              code: specialty?.code!,
              display: specialty?.display!,
            },
            "code",
            "display"
          )
        )
      : [];

    setValue("specializations", specializations);
  }, [defaultValues?.specializations, setValue]);

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <SystemCodeAutocomplete
          name={"specializations"}
          label={t("Specializations")}
          multiple
          isCodeHidden
          filter={{ codeSystemCode: CodeSystemCode.Speciality }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                label={<TruncateTypography text={option?.value?.display || ""} />}
                {...getTagProps({ index })}
                // eslint-disable-next-line react/jsx-handler-names
                onDelete={() => {
                  getTagProps({ index })?.onDelete(option);
                  handleDelete(option?.value?.id);
                }}
              />
            ))
          }
        />
      </Grid>
    </Grid>
  );
};

/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleCategoryQueryVariables = Types.Exact<{
  articleCategoryId: Types.Scalars['ID'];
}>;


export type ArticleCategoryQuery = { __typename?: 'Query', articleCategory?: { __typename?: 'ArticleCategory', code?: string | null, display?: string | null, displayAr?: string | null, id: string } | null };


export const ArticleCategoryDocument = gql`
    query ArticleCategory($articleCategoryId: ID!) {
  articleCategory(id: $articleCategoryId) {
    code
    display
    displayAr
    id
  }
}
    `;

/**
 * __useArticleCategoryQuery__
 *
 * To run a query within a React component, call `useArticleCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoryQuery({
 *   variables: {
 *      articleCategoryId: // value for 'articleCategoryId'
 *   },
 * });
 */
export function useArticleCategoryQuery(baseOptions: Apollo.QueryHookOptions<ArticleCategoryQuery, ArticleCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleCategoryQuery, ArticleCategoryQueryVariables>(ArticleCategoryDocument, options);
      }
export function useArticleCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleCategoryQuery, ArticleCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleCategoryQuery, ArticleCategoryQueryVariables>(ArticleCategoryDocument, options);
        }
export type ArticleCategoryQueryHookResult = ReturnType<typeof useArticleCategoryQuery>;
export type ArticleCategoryLazyQueryHookResult = ReturnType<typeof useArticleCategoryLazyQuery>;
export type ArticleCategoryQueryResult = Apollo.QueryResult<ArticleCategoryQuery, ArticleCategoryQueryVariables>;
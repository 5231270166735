import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHealthSymptomsNewHook } from "../New/useHealthSymptomsNewHook";
import { IFormData } from "../types";
import { HealthSymptomInformation } from "./HealthSymptomInformation";
import { HealthSymptomSpecializations } from "./HealthSymptomSpecializations";

export const HealthSymptomForm: FC = () => {
  const { t } = useTranslation("gcadmin");
  const methods = useForm<IFormData>({
    mode: "onChange",
  });
  const { handleSubmit, watch } = methods;
  const specializations = watch("specializations");

  const { errors, isLoading, healthSymptom, handleCancel, handleCreate, handleErrors, isSubmitting } =
    useHealthSymptomsNewHook(specializations);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleCreate)}>
        <PageWrapper
          actions={
            <FormActions
              isLoading={isLoading}
              hasCancel
              hasFormButton
              formButtonTitle={t("Save")}
              onNavigation={handleCancel}
              newButtonDisabled={isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard loading={isLoading} title={t("Health symptom information")} doYouHaveData>
                <HealthSymptomInformation defaultValues={healthSymptom!} errors={errors} onHandleErrors={handleErrors} />
              </FormCard>
            </Grid>
            {
              <Grid item xs={12}>
                <FormCard loading={isLoading} title={t("Health symptom specializations")} doYouHaveData>
                  <HealthSymptomSpecializations defaultValues={healthSymptom!} />
                </FormCard>
              </Grid>
            }
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};

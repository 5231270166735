import { getArticleCategoriesAutocompleteFilter } from "@health/autocompletes";
import { getYesNoAutocompleteFilter } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomTableColumnProps, GridImageModal, ShowButton, TruncateTypography, Typography } from "@toolkit/ui";
import { ArticlePreview } from "pages/KnowledgeBaseArticles/Components";
import { ArticleActivationButton } from "pages/KnowledgeBaseArticles/Components/ArticleActivationButton";
import { useMemo } from "react";
import { ArticlesQueryNode } from "../../types";

export const useArticleCategoriesListTableColumns = (): CustomTableColumnProps<ArticlesQueryNode>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image"),
        accessor: ({ headerImage }) => (
          <GridImageModal
            image={headerImage!}
            title={t("Product Image")}
            fallbackText={t("Product Image Not Provided")}
            altText={t("Product")}
          />
        ),
      },
      {
        key: "title",
        header: t("Title"),
        accessor: ({ title }) => <TruncateTypography text={title || "-"} />,
        filter: {
          type: "string",
          name: "title",
        },
      },
      {
        key: "Category",
        header: t("Category"),
        accessor: ({ category }) => <TruncateTypography text={pickLocalizedValue(category?.display, category?.displayAr) || "-"} />,
        filter: getArticleCategoriesAutocompleteFilter({ name: "categories", multiple: true }),
      },
      {
        key: "readTimeMinutes",
        header: t("Read Time Minutes"),
        accessor: ({ readTimeMinutes }) => readTimeMinutes || "-",
      },
      {
        key: "relatedArticles",
        header: t("Related Articles"),
        accessor: ({ relatedArticles }) => (
          <CustomDialog type='info' button={<ShowButton />} title={t("Related Articles")}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {relatedArticles?.length ? relatedArticles?.map(article => article?.title).join("\n") : t("Theres No Related Articles ")}
            </Typography>
          </CustomDialog>
        ),
      },
      {
        key: "tags",
        header: t("Tags"),
        accessor: ({ tags }) => (
          <CustomDialog type='info' button={<ShowButton />} title={t("Tags")}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>{tags?.length ? tags.join("\n\r") : t("Theres No Tags")}</Typography>
          </CustomDialog>
        ),
        filter: {
          type: "string",
          name: "tags",
          getValueForBackend: value => (value ? [value] : null),
        },
      },
      {
        key: "preview",
        header: t("Preview"),
        accessor: row => <ArticlePreview {...row} />,
      },
      {
        key: "activation",
        header: t("Active"),
        accessor: ({ id, active }) => <ArticleActivationButton articleId={id} isActive={!!active} />,
        filter: getYesNoAutocompleteFilter({ name: "active" }),
      },
    ];
  }, []);
};

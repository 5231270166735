import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { ArticleInformationForm } from "../ArticleFromInformation";
import { ArticleUpsertFormSchema, articleUpsertFormSchemaDefaultValues, IArticleUpsertFormValues } from "./ArticleUpsertFormSchema";
import { convertArticleToFormValues } from "../../utils";
import { useNavigate } from "react-router-dom";
import { articlesPaths } from "../../constants";
import { ArticleQueryData } from "pages/KnowledgeBaseArticles/types";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { ArticleFormContents } from "../ArticleContent/ArticleFormContnent";
import { ArticleFormDescriptions } from "../ArticleDescription/ArticleFormDescriptions";

type ArticleUpsertFormProps = {
  article?: ArticleQueryData;
  isLoading?: boolean;
  isSubmitting?: boolean;
  submitButtonLabel: string;
  onChange: (values: IArticleUpsertFormValues) => void;
};

export const ArticleUpsertForm: FC<ArticleUpsertFormProps> = props => {
  const { article, isLoading, isSubmitting, submitButtonLabel, onChange } = props;
  const navigate = useNavigate();
  const form = useCustomForm<IArticleUpsertFormValues>({
    schema: ArticleUpsertFormSchema,
    defaultValues: articleUpsertFormSchemaDefaultValues,
    mode: "onChange",
  });

  const { handleSubmit, setValues } = form;
  const { t } = useTranslation("domains");
  const isButtonDisabled = isLoading || isSubmitting;

  const { accessToken } = useOidcUserProfile();

  const onSubmit = (values: IArticleUpsertFormValues) => {
    console.log(values);
    onChange(values);
  };
  const handleCancel = () => {
    navigate(articlesPaths.main.fullPath);
  };
  useEffect(() => {
    if (article) {
      const _article = convertArticleToFormValues(article!);

      setValues(_article);
    }
  }, [article, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={handleCancel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t(" Information")} loading={isLoading} doYouHaveData>
                <ArticleInformationForm />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Description")} loading={isLoading} doYouHaveData>
                <ArticleFormDescriptions article={article} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Contents")} loading={isLoading} doYouHaveData>
                <ArticleFormContents article={article} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Header Image")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ImageUploadController control={form.control} name={"headerImage"} token={accessToken!} />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};

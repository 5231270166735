/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramTemplateActivityGetQueryVariables = Types.Exact<{
  templateActivityId: Types.Scalars['ID'];
}>;


export type GuidedCareProgramTemplateActivityGetQuery = { __typename?: 'Query', templateActivity?: { __typename?: 'TemplateActivity', templateActivityEventItems?: Array<{ __typename?: 'TemplateActivityEventItem', offsetInDays?: number | null, beforeCallMessageOffsetDays?: number | null, afterCallMessageOffsetDays?: number | null, beforeCallMessage?: { __typename?: 'MedicalMessage', id: string, name?: string | null } | null, afterCallMessage?: { __typename?: 'MedicalMessage', id: string, name?: string | null } | null } | null> | null } | null };


export const GuidedCareProgramTemplateActivityGetDocument = gql`
    query GuidedCareProgramTemplateActivityGet($templateActivityId: ID!) {
  templateActivity(id: $templateActivityId) {
    templateActivityEventItems {
      offsetInDays
      beforeCallMessageOffsetDays
      beforeCallMessage {
        id
        name
      }
      afterCallMessage {
        id
        name
      }
      afterCallMessageOffsetDays
    }
  }
}
    `;

/**
 * __useGuidedCareProgramTemplateActivityGetQuery__
 *
 * To run a query within a React component, call `useGuidedCareProgramTemplateActivityGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramTemplateActivityGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareProgramTemplateActivityGetQuery({
 *   variables: {
 *      templateActivityId: // value for 'templateActivityId'
 *   },
 * });
 */
export function useGuidedCareProgramTemplateActivityGetQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareProgramTemplateActivityGetQuery, GuidedCareProgramTemplateActivityGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareProgramTemplateActivityGetQuery, GuidedCareProgramTemplateActivityGetQueryVariables>(GuidedCareProgramTemplateActivityGetDocument, options);
      }
export function useGuidedCareProgramTemplateActivityGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareProgramTemplateActivityGetQuery, GuidedCareProgramTemplateActivityGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareProgramTemplateActivityGetQuery, GuidedCareProgramTemplateActivityGetQueryVariables>(GuidedCareProgramTemplateActivityGetDocument, options);
        }
export type GuidedCareProgramTemplateActivityGetQueryHookResult = ReturnType<typeof useGuidedCareProgramTemplateActivityGetQuery>;
export type GuidedCareProgramTemplateActivityGetLazyQueryHookResult = ReturnType<typeof useGuidedCareProgramTemplateActivityGetLazyQuery>;
export type GuidedCareProgramTemplateActivityGetQueryResult = Apollo.QueryResult<GuidedCareProgramTemplateActivityGetQuery, GuidedCareProgramTemplateActivityGetQueryVariables>;
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "@toolkit/i18n";
import {
  Box,
  CustomToggleButton,
  FilterGrid,
  FormControlLabel,
  MainListActions,
  PageWrapper,
  TableGrid,
  TableSettingComponent,
  useTheme,
} from "@toolkit/ui";
import { useGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { IGuidedCareTemplateAllActivitiesItemsContainerParams } from "pages/Templates/types";
import { GuidedCareTemplateActivitiesItemList } from "../../components/TemplateActivitiesItemList/GuidedCareTemplateActivitiesItemList";
import { GuidedCareTemplateActivitiesItemsTimeline } from "../../components/TemplateActivitiesItemsTimeline/GuidedCareTemplateActivitiesItemsTimeline";

export const GuidedCareTemplateActivitiesItemListContainer = () => {
  const [showTimeLine, setShowTimeLine] = useState(true);

  const { t } = useTranslation("gcadmin");

  const { setGuidedCareTemplateActivitiesItemListContainerBreadcrumb } = useGuidedCareTemplatesBreadcrumbs();

  const { templateId } = useParams<IGuidedCareTemplateAllActivitiesItemsContainerParams>();

  const handleToggleTimeLine = () => {
    setShowTimeLine(!showTimeLine);
  };

  useEffect(() => {
    setGuidedCareTemplateActivitiesItemListContainerBreadcrumb();
  }, [setGuidedCareTemplateActivitiesItemListContainerBreadcrumb]);
  const theme = useTheme();
  return (
    <GuidedCareTemplateActivitiesItemList templateId={templateId!}>
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            {!showTimeLine && <TableSettingComponent />}

            <MainListActions />

            <Box p={1}>
              <FormControlLabel
                sx={{ minWidth: 140, marginInline: "5px", fontSize: theme.mixins.fonts.fontSize.xs, whiteSpace: "nowrap" }}
                control={<CustomToggleButton value={showTimeLine} defaultChecked={showTimeLine} onChange={handleToggleTimeLine} />}
                label={showTimeLine ? t("Timeline View") : t("Table View")}
              />
            </Box>
          </>
        }
      >
        {showTimeLine ? <GuidedCareTemplateActivitiesItemsTimeline /> : <TableGrid />}
      </PageWrapper>
    </GuidedCareTemplateActivitiesItemList>
  );
};

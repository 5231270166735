import { adminManagedListsRoutes } from "pages/AdminManagedLists/route";
import { agentsRoutes } from "pages/AIAgents";
import { decisionsRoutes } from "pages/Decisions/constants";
import { guidedCareRoutes } from "pages/GuidedCare/constants";
import { HealthSymptomsRoutes } from "pages/HealthSymptoms/route";
import { knowledgeBaseRoutes } from "pages/KnowledgeBase";
import { medicalFormsRoutes } from "pages/MedicalForms";
import { medicalMessagesRoutes } from "pages/MedicalMessages/constants";
import { healthConditionsRoutes } from "pages/HealthConditions/constants";
import { healthTemplatesRoutes } from "pages/HealthTemplates/constants";
import { healthParametersRoutes } from "pages/HealthParameters/constants";

export const ROUTE_PATHS = {
  adminManagedListsRoutes,
  guidedCareRoutes,
  medicalFormsRoutes,
  medicalMessagesRoutes,
  healthConditionsRoutes,
  healthParametersRoutes,
  healthTemplatesRoutes,
  HealthSymptomsRoutes,
  decisionsRoutes,
  knowledgeBaseRoutes,
  agentsRoutes,
};

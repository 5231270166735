import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { articlesBreadcrumb } from "../../constants";
import { articlesPaths } from "../../constants";
import { ArticleUpsertForm, IArticleUpsertFormValues } from "../../forms";
import { ArticleCreateMutation, useArticleCreateMutation } from "../../gql";
import { convertArticleFormValuesToBackEndValues } from "../../utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ArticleCreateContainer: React.FC = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchArticleCreateMutation, { loading: isSubmitting }] = useArticleCreateMutation({
    onCompleted: (data: ArticleCreateMutation) => {
      if (data?.articleCreate?.id) {
        succeeded(t("Article created successfully!"));
        navigate(articlesPaths.main.fullPath);
      } else {
        failed(t("Article creation failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onArticleUpsertFormChange = (values: IArticleUpsertFormValues) => {
    const _values = convertArticleFormValuesToBackEndValues(values);

    fetchArticleCreateMutation({
      variables: {
        input: _values,
      },
    });
  };
  useEffect(() => {
    const { title, main, create } = articlesBreadcrumb();
    setBreadCrumb({ title, values: [main, create] });
  }, [setBreadCrumb, t]);
  return <ArticleUpsertForm isSubmitting={isSubmitting} submitButtonLabel={t("Create")} onChange={onArticleUpsertFormChange} />;
};

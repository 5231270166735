/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type HealthSymptomFragmentFragment = { __typename?: 'HealthSymptom', id: string, name: string, created: any, specializations?: Array<{ __typename?: 'HealthSymptomSpecialization', id: string, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null> | null };

export const HealthSymptomFragmentFragmentDoc = gql`
    fragment HealthSymptomFragment on HealthSymptom {
  id
  name
  created
  specializations {
    id
    code
    display
    arabicDisplay
  }
}
    `;
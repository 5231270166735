/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleUpdateMutationVariables = Types.Exact<{
  articleUpdateId: Types.Scalars['ID'];
  input: Types.ArticleInput;
}>;


export type ArticleUpdateMutation = { __typename?: 'Mutation', articleUpdate?: { __typename?: 'Article', id: string, active?: boolean | null } | null };


export const ArticleUpdateDocument = gql`
    mutation ArticleUpdate($articleUpdateId: ID!, $input: ArticleInput!) {
  articleUpdate(id: $articleUpdateId, input: $input) {
    id
    active
  }
}
    `;
export type ArticleUpdateMutationFn = Apollo.MutationFunction<ArticleUpdateMutation, ArticleUpdateMutationVariables>;

/**
 * __useArticleUpdateMutation__
 *
 * To run a mutation, you first call `useArticleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleUpdateMutation, { data, loading, error }] = useArticleUpdateMutation({
 *   variables: {
 *      articleUpdateId: // value for 'articleUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ArticleUpdateMutation, ArticleUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleUpdateMutation, ArticleUpdateMutationVariables>(ArticleUpdateDocument, options);
      }
export type ArticleUpdateMutationHookResult = ReturnType<typeof useArticleUpdateMutation>;
export type ArticleUpdateMutationResult = Apollo.MutationResult<ArticleUpdateMutation>;
export type ArticleUpdateMutationOptions = Apollo.BaseMutationOptions<ArticleUpdateMutation, ArticleUpdateMutationVariables>;
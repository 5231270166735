import { HealthSymptom, HealthSymptomError } from "@health/queries/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  healthSymptomsFieldBreadcrumb,
  healthSymptomsListBreadcrumb,
  healthSymptomsNewBreadcrumb,
  healthSymptomsTitleBreadcrumb,
} from "pages/HealthSymptoms/constants/healthSymptomsBreadcrumbs";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHealthSymptomCreateMutation, useHealthSymptomQuery, useHealthSymptomSpecializationsCreateMutation } from "../../gql";
import { IFormData } from "../types";

export const useHealthSymptomsNewHook = specializations => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const params = useParams();
  const { setBreadCrumb } = useBreadCrumbs();
  const { failed, succeeded } = useAddToast();
  const [errors, setErrors] = React.useState<HealthSymptomError[]>([]);
  const { data: healthSymptomData, loading: loadingHealthSymptom } = useHealthSymptomQuery({
    variables: {
      healthSymptomId: params?.id,
    },
    skip: !params?.id,
  });
  const handleErrors = (errors: HealthSymptomError[]) => {
    setErrors(errors);
  };
  const [fetchHealthSymptomSpecializationsCreate, { loading: isSubmitting }] = useHealthSymptomSpecializationsCreateMutation({
    onCompleted: response => {
      const specializationErrors = response?.healthSymptomSpecializationsCreate?.specializationErrors || [];
      if (specializationErrors?.length > 0) {
        failed(t(formatMessageErrors(specializationErrors)));
      } else {
        succeeded(params?.id ? t("Health symptom updated successfully") : t("Health symptom created successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const [fetchHealthSymptomCreate, { loading: isLoading }] = useHealthSymptomCreateMutation({
    onCompleted: response => {
      const mutationErrors = response?.healthSymptomCreate?.healthSymptomErrors;
      if (mutationErrors && mutationErrors.length > 0) {
        handleErrors(mutationErrors);
        mutationErrors?.map(err => {
          return failed(t(err?.message!));
        });
      } else {
        const healthSymptomId = response?.healthSymptomCreate?.healthSymptom?.id;
        healthSymptomId &&
          fetchHealthSymptomSpecializationsCreate({
            variables: {
              healthSymptomSpecializationsCreateId: healthSymptomId!,
              specializations: specializations?.map(specialization => specialization?.value?.code),
            },
          });
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const healthSymptom = healthSymptomData?.healthSymptom as HealthSymptom;

  const handleHealthSymptomCreate = (requestInput: IFormData) => {
    fetchHealthSymptomCreate({
      variables: {
        input: {
          name: requestInput?.name,
        },
      },
    });
  };

  const handleHealthSymptomSpecializationsCreate = requestInput => {
    fetchHealthSymptomSpecializationsCreate({
      variables: {
        healthSymptomSpecializationsCreateId: healthSymptom?.id!,
        specializations: requestInput?.specializations?.map(specialization => specialization?.value?.code),
      },
    });
  };
  const handleCreate = input => {
    if (healthSymptom?.id) {
      handleHealthSymptomSpecializationsCreate(input);
    } else {
      handleHealthSymptomCreate(input);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    setBreadCrumb({
      title: healthSymptomsTitleBreadcrumb(),
      values: [
        healthSymptomsListBreadcrumb(),
        !healthSymptom ? healthSymptomsNewBreadcrumb() : healthSymptomsFieldBreadcrumb(healthSymptom?.name),
      ],
    });
  }, [healthSymptom?.name]);

  return {
    errors,
    healthSymptom,
    isLoading: isLoading || loadingHealthSymptom,
    handleCreate,
    handleCancel,
    handleErrors,
    isSubmitting: isLoading || isSubmitting,
  };
};

import { GridProvider } from "@health/domains";
import { HealthSymptomOrderField } from "@health/queries/types";
import { FilterGrid, MainListActions, OrderDirection, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { healthSymptomsTitleBreadcrumb } from "pages/HealthSymptoms/constants/healthSymptomsBreadcrumbs";
import { healthSymptomsPaths } from "pages/HealthSymptoms/constants/healthSymptomsPaths";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useHealthSymptomDeleteMutation, useHealthSymptomsQuery } from "../gql";
import { useHealthSymptomsColumns } from "./components/useHealthSymptomsColumns";

export const HealthSymptoms = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const handleAddNew = () => {
    navigate(healthSymptomsPaths.newPath.fullPath);
  };

  const handleEditRow = data => {
    navigate(healthSymptomsPaths.editPath.fullPathWithParams(data?.id));
  };

  useEffect(() => {
    setBreadCrumb({
      title: healthSymptomsTitleBreadcrumb(),
    });
  }, []);

  return (
    <GridProvider
      gridName='healthSymptoms'
      dataAccessor='healthSymptoms'
      columns={useHealthSymptomsColumns()}
      query={useHealthSymptomsQuery}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: true,
        onEditRow: handleEditRow,
      }}
      hasTableSetting
      variables={{
        sortBy: {
          direction: OrderDirection.Desc,
          field: HealthSymptomOrderField.Created,
        },
      }}
      deleteItemProps={{
        name: "health Symptom",
        entityTypeName: "HealthSymptom",
        useDeleteMutation: useHealthSymptomDeleteMutation,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleAddNew} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};

import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { articlesCategoriesBreadcrumb } from "../../constants";
import { articlesCategoriesPaths } from "../../constants";
import { ArticleCategoryUpsertForm, IArticleCategoryUpsertFormValues } from "../../forms";
import { ArticleCategoryCreateMutation, useArticleCategoryCreateMutation } from "../../gql";
import { convertArticleCategoryFormValuesToBackEndValues } from "../../utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ArticleCategoryCreateContainer: React.FC = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchArticleCategoryCreateMutation, { loading: isSubmitting }] = useArticleCategoryCreateMutation({
    onCompleted: (data: ArticleCategoryCreateMutation) => {
      if (data?.articleCategoryCreate?.id) {
        succeeded(t("Article category created successfully!"));
        navigate(articlesCategoriesPaths.main.fullPath);
      } else {
        failed(t("Article category creation failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onArticleCategoryUpsertFormChange = (values: IArticleCategoryUpsertFormValues) => {
    const _values = convertArticleCategoryFormValuesToBackEndValues(values);

    fetchArticleCategoryCreateMutation({
      variables: {
        input: _values,
      },
    });
  };
  useEffect(() => {
    const { title, main, create } = articlesCategoriesBreadcrumb();
    setBreadCrumb({ title, values: [main, create] });
  }, [setBreadCrumb, t]);
  return (
    <ArticleCategoryUpsertForm isSubmitting={isSubmitting} submitButtonLabel={t("Create")} onChange={onArticleCategoryUpsertFormChange} />
  );
};

import { makeStyles } from "@toolkit/ui";

export const useGuidedCareTemplateInterventionsStyle = makeStyles()(theme => ({
  title: {
    // @ts-ignore
    fontSize: theme.mixins.fonts.fontSize.md,
    // @ts-ignore
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));

import { isGuidedCareTeamMemberNonManagement } from "@health/enum-options";
import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography, createAutocompleteOption, useModal } from "@toolkit/ui";
import { getTeamMemberLabel } from "pages/Templates/others";
import { IGuidedCareTemplateMemberItem, IGuidedCareTemplateMemberUpsertFormEvent } from "pages/Templates/types";
import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { GuidedCareTemplateMemberUpsertModal } from "../../modals/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertModal";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { useGuidedCareTemplateMembersColumns } from "./useGuidedCareTemplateMembersColumns";
import { useGuidedCareTemplateMembersFormStyle } from "./useGuidedCareTemplateMembersFormStyle";

export const GuidedCareTemplateMembersForm = () => {
  const [selectedMember, setSelectedMember] = useState<IGuidedCareTemplateMemberItem>();

  const { t } = useTranslation("gcadmin");
  const { open, handleOpen, handleClose } = useModal();

  const { classes } = useGuidedCareTemplateMembersFormStyle();

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();
  const { watch, control, setValue } = form;

  const activities = watch("activities");

  const { fields, append, remove, update } = useFieldArray<IGuidedCareTemplateUpsertFormValues, "teamMembers">({
    control,
    name: "teamMembers",
  });

  const onAddClick = () => {
    setSelectedMember(undefined);
    handleOpen();
  };

  const onGuidedCareTemplateMemberUpsertModalChange = (event: IGuidedCareTemplateMemberUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
      handleClose();
    } else if (event.type === "UPDATE" && selectedMember) {
      const updatedFieldIndex = fields.findIndex(item => item.id === selectedMember.id);
      if (updatedFieldIndex > -1) {
        const updatedTeaMember = { ...fields[updatedFieldIndex], ...event.payload.values };
        update(updatedFieldIndex, updatedTeaMember);

        // Update activities associated with the updated member
        const updatedActivities = activities?.map(item =>
          item.teamMember?.value?.uniqueId === updatedTeaMember.uniqueId
            ? { ...item, teamMember: createAutocompleteOption(updatedTeaMember, "uniqueId", getTeamMemberLabel) }
            : item
        );
        setValue("activities", updatedActivities);
      }
      handleClose();
    }
  };

  const onEditRowClick = (item: IGuidedCareTemplateMemberItem) => {
    setSelectedMember(item);
    handleOpen();
  };

  const onDeleteRowClick = (item: IGuidedCareTemplateMemberItem) => {
    const itemIndex = fields.findIndex(field => field.id === item.id);

    if (itemIndex > -1) {
      remove(itemIndex);

      // Remove activities associated with the deleted member
      const updatedActivities = activities?.filter(activity => activity.teamMember?.value?.uniqueId !== fields[itemIndex]?.uniqueId);
      setValue("activities", updatedActivities);
    }
  };

  return (
    <Grid container spacing={2}>
      <GuidedCareTemplateMemberUpsertModal
        isOpen={open}
        onClose={handleClose}
        member={selectedMember}
        onChange={onGuidedCareTemplateMemberUpsertModalChange}
      />

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            <Typography className={classes.title}> {t("Care Team Members")} </Typography>
          </Grid>

          <Grid xs={4} lg={2}>
            <Button startIcon={<PlusIcon />} fullWidth onClick={onAddClick}>
              {t("Add Member")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useGuidedCareTemplateMembersColumns()}
          isEditVisible
          isDeleteVisible
          isRowDeletable={({ position }) => isGuidedCareTeamMemberNonManagement(position?.value)}
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
          hasFooter={false}
          scrollable
        />
      </Grid>
    </Grid>
  );
};

/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalMessageVariantMedicalMessageGetQueryVariables = Types.Exact<{
  medicalMessageId: Types.Scalars['ID'];
}>;


export type MedicalMessageVariantMedicalMessageGetQuery = { __typename?: 'Query', medicalMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null, medicalMessageCategory?: Types.MedicalMessageCategory | null, medicalMessageType?: Types.MedicalMessageType | null } | null };


export const MedicalMessageVariantMedicalMessageGetDocument = gql`
    query MedicalMessageVariantMedicalMessageGet($medicalMessageId: ID!) {
  medicalMessage(id: $medicalMessageId) {
    id
    code
    name
    medicalMessageCategory
    medicalMessageType
  }
}
    `;

/**
 * __useMedicalMessageVariantMedicalMessageGetQuery__
 *
 * To run a query within a React component, call `useMedicalMessageVariantMedicalMessageGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalMessageVariantMedicalMessageGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalMessageVariantMedicalMessageGetQuery({
 *   variables: {
 *      medicalMessageId: // value for 'medicalMessageId'
 *   },
 * });
 */
export function useMedicalMessageVariantMedicalMessageGetQuery(baseOptions: Apollo.QueryHookOptions<MedicalMessageVariantMedicalMessageGetQuery, MedicalMessageVariantMedicalMessageGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalMessageVariantMedicalMessageGetQuery, MedicalMessageVariantMedicalMessageGetQueryVariables>(MedicalMessageVariantMedicalMessageGetDocument, options);
      }
export function useMedicalMessageVariantMedicalMessageGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalMessageVariantMedicalMessageGetQuery, MedicalMessageVariantMedicalMessageGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalMessageVariantMedicalMessageGetQuery, MedicalMessageVariantMedicalMessageGetQueryVariables>(MedicalMessageVariantMedicalMessageGetDocument, options);
        }
export type MedicalMessageVariantMedicalMessageGetQueryHookResult = ReturnType<typeof useMedicalMessageVariantMedicalMessageGetQuery>;
export type MedicalMessageVariantMedicalMessageGetLazyQueryHookResult = ReturnType<typeof useMedicalMessageVariantMedicalMessageGetLazyQuery>;
export type MedicalMessageVariantMedicalMessageGetQueryResult = Apollo.QueryResult<MedicalMessageVariantMedicalMessageGetQuery, MedicalMessageVariantMedicalMessageGetQueryVariables>;
import { HealthSymptom, HealthSymptomError } from "@health/queries/types";
import {
  combineErrors,
  formGirdBreakPoints,
  formGirdSpacing,
  getMaxLengthValidation,
  getRequiredValidation,
  validateValueIsNotEmpty,
} from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const HealthSymptomInformation: FC<{
  defaultValues?: HealthSymptom;
  errors: HealthSymptomError[];
  onHandleErrors: (errors: HealthSymptomError[]) => void;
}> = ({ defaultValues, errors, onHandleErrors }) => {
  const { t } = useTranslation("gcadmin");
  const {
    register,
    formState: { errors: formErrors },
    clearErrors,
  } = useFormContext();
  const combinedErrors = combineErrors(formErrors, errors);
  const handleOnChangeText = () => {
    if (combinedErrors?.name) {
      onHandleErrors([]);
      clearErrors("name");
    }
  };
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          label={t("Name")}
          fullWidth
          placeholder={t("Name")}
          disabled={Boolean(defaultValues)}
          defaultValue={defaultValues?.name}
          helperText={t(combinedErrors?.name?.message)}
          error={Boolean(combinedErrors?.name?.message)}
          {...register("name", {
            required: getRequiredValidation(t, !defaultValues?.name),
            validate: value => value && validateValueIsNotEmpty(value, t),
            maxLength: getMaxLengthValidation(t, 255),
          })}
          onChange={handleOnChangeText}
        />
      </Grid>
    </Grid>
  );
};
HealthSymptomInformation.defaultProps = {
  defaultValues: undefined,
};

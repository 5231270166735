import { FormProvider, UseFormReturn } from "react-hook-form";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useCustomForm } from "@toolkit/core";
import { FormTextField, Grid } from "@toolkit/ui";
import { IGuidedCareTemplateGoalFormUpsertFormEvent } from "../../types";
import {
  guidedCareTemplateGoalUpsertFormDefaultValues,
  guidedCareTemplateGoalUpsertFormSchema,
  IGuidedCareTemplateGoalUpsertFormValues,
} from "./GuidedCareTemplateGoalUpsertFormSchema";
import { uniqueId } from "lodash";
import { HealthParametersAutocomplete } from "@health/autocompletes";

type GuidedCareTemplateGoalUpsertFormProps = {
  goal?: IGuidedCareTemplateGoalUpsertFormValues;
  onChange: (event: IGuidedCareTemplateGoalFormUpsertFormEvent) => void;
};

export type GuidedCareTemplateGoalUpsertFormRef = {
  getForm: () => UseFormReturn<IGuidedCareTemplateGoalUpsertFormValues>;
  submit: () => void;
};

const GuidedCareTemplateGoalUpsertFormForwardRef: ForwardRefRenderFunction<
  GuidedCareTemplateGoalUpsertFormRef,
  GuidedCareTemplateGoalUpsertFormProps
> = (props, ref) => {
  const { goal, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IGuidedCareTemplateGoalUpsertFormValues>({
    defaultValues: guidedCareTemplateGoalUpsertFormDefaultValues,
    schema: guidedCareTemplateGoalUpsertFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IGuidedCareTemplateGoalUpsertFormValues) => {
    if (goal) {
      onChange({
        type: "UPDATE",
        payload: {
          values: {
            ...values,
            recommendations: goal?.recommendations,
          },
        },
      });
    } else {
      onChange({
        type: "CREATE",
        payload: {
          values: {
            ...values,
            uniqueId: uniqueId("goal_"),
          },
        },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (goal) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { recommendations, ...rest } = goal;
      setValues(rest);
    }
  }, [goal, setValues]);

  return (
    <FormProvider {...form}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
        </Grid>

        <Grid item xs={6}>
          <FormTextField name={"nameAr"} label={t("Name (Arabic)")} placeholder={t("Name (Arabic)")} />
        </Grid>

        <Grid item xs={6}>
          <HealthParametersAutocomplete name={"healthParameter"} />
        </Grid>

        <Grid item xs={6}>
          <FormTextField name={"target"} label={t("Target")} placeholder={t("Target")} />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormTextField name={"description"} label={t("Description")} placeholder={t("Description")} multiline rows={3} />
            </Grid>

            <Grid item xs={6}>
              <FormTextField
                name={"descriptionAr"}
                label={t("Description (Arabic)")}
                placeholder={t("Description (Arabic)")}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export const GuidedCareTemplateGoalUpsertForm = forwardRef(GuidedCareTemplateGoalUpsertFormForwardRef);

import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import { TFunction } from "@toolkit/i18n";
import { DermatologyIcon, useTheme } from "@toolkit/ui";
import { healthSymptomsPaths, healthSymptomsRoute } from "pages/HealthSymptoms/constants/healthSymptomsPaths";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { HealthSymptomsNewPage } from "./form";
import { HealthSymptoms } from "./list";

export const HealthSymptomsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  const theme = useTheme();
  return {
    id: "health-symptoms-routes",
    text: t("Health Symptoms", { ns: "gcadmin" }),
    icon: <DermatologyIcon fill={theme.palette.primary.main} />,
    route: healthSymptomsRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageHealthSymptoms),
    subItems: [
      {
        id: "health-symptoms-list-route",
        text: t("Condition Management", { ns: "gcadmin" }),
        route: healthSymptomsPaths.listPath.route,
        fullPath: healthSymptomsPaths.listPath.fullPath,
        element: <HealthSymptoms />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-symptoms-new-route",
        text: t("New Condition", { ns: "gcadmin" }),
        route: healthSymptomsPaths.newPath.route,
        fullPath: healthSymptomsPaths.newPath.fullPath,
        hidden: true,
        element: <HealthSymptomsNewPage />,
      },
      {
        id: "health-symptoms-edit-route",
        text: t("Edit Condition", { ns: "gcadmin" }),
        route: healthSymptomsPaths.editPath.route,
        fullPath: healthSymptomsPaths.editPath.fullPath,
        hidden: true,
        element: <HealthSymptomsNewPage />,
      },
    ],
  };
};

import { createZodAutocompleteObject } from "@toolkit/ui";
import { MedicalMessageCategory, MedicalMessageType } from "@health/queries/types";
import { z } from "zod";

export const MedicalMessageUpsertFormSchema = z.object({
  name: z.string().min(1).max(255),
  code: z.string().min(1).max(255),
  headerImage: z.string(),
  medicalMessageType: createZodAutocompleteObject(MedicalMessageType),
  medicalMessageCategory: createZodAutocompleteObject(MedicalMessageCategory),
  healthCondition: createZodAutocompleteObject(
    z.object({
      id: z.string(),
      code: z.string(),
      display: z.string(),
    })
  ).nullable(),
});

export type IMedicalMessageUpsertFormValues = z.infer<typeof MedicalMessageUpsertFormSchema>;

export const medicalMessageDefaultValues: Partial<IMedicalMessageUpsertFormValues> = {
  name: undefined,
  headerImage: undefined,
  medicalMessageType: undefined,
  medicalMessageCategory: undefined,
  healthCondition: undefined,
};

import { getSystemCodeAutocompleteFilter } from "@health/autocompletes";
import { CodeSystemCode, HealthSymptom, HealthSymptomOrderField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomTableColumnProps, OrderDirection, TruncateTypography, Typography, format } from "@toolkit/ui";
import { useMemo } from "react";

export type HealthSymptomColumnTypes = CustomTableColumnProps<HealthSymptom>[];
export const useHealthSymptomsColumns = (): HealthSymptomColumnTypes => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name }) => <TruncateTypography text={name!} />,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: HealthSymptomOrderField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "created",
        header: t("Created"),
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: HealthSymptomOrderField.Created,
        accessor: row => format(row?.created),
      },
      {
        key: "specializations",
        header: t("Specializations"),
        accessor: ({ specializations }) => (
          <CustomDialog type='info' title={"Discount Products"}>
            <Typography sx={{ whiteSpace: "pre-line", lineHeight: 2 }}>
              {specializations?.length
                ? specializations?.map(specialty => specialty?.display).join("\n")
                : t("There are no specializations")}
            </Typography>
          </CustomDialog>
        ),
        filter: {
          ...getSystemCodeAutocompleteFilter({
            name: "specializations",
            multiple: true,
            isCodeHidden: true,
            queryVariables: { codeSystemCode: CodeSystemCode.Speciality },
          }),
        },
      },
    ];
  }, [t]);
};

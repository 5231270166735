import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { articlesBreadcrumb } from "../../constants";
import { articlesPaths } from "../../constants";
import { ArticleUpsertForm, IArticleUpsertFormValues } from "../../forms";
import { ArticleUpdateMutation, useArticleUpdateMutation } from "../../gql";
import { useArticleQuery } from "../../gql";
import { IArticleUpdateContainerParams } from "../../types";
import { convertArticleFormValuesToBackEndValues } from "../../utils";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ArticleUpdateContainer: React.FC = () => {
  const { t } = useTranslation("gcadmin");
  const { articleId } = useParams<IArticleUpdateContainerParams>();

  const { data, loading } = useArticleQuery({
    variables: { articleId: articleId! },
    skip: !articleId,
  });
  const article = data?.article;

  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchArticleUpdateMutation, { loading: isSubmitting }] = useArticleUpdateMutation({
    onCompleted: (data: ArticleUpdateMutation) => {
      if (data?.articleUpdate?.id) {
        succeeded(t("Article updated successfully!"));
        navigate(articlesPaths.main.fullPath);
      } else {
        failed(t("Article update failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onArticleUpsertFormChange = (values: IArticleUpsertFormValues) => {
    const _values = convertArticleFormValuesToBackEndValues(values);

    fetchArticleUpdateMutation({
      variables: {
        articleUpdateId: articleId!,
        input: _values,
      },
    });
  };
  useEffect(() => {
    const { title, main, update } = articlesBreadcrumb();
    setBreadCrumb({ title, values: [main, update(article?.title!)] });
  }, [setBreadCrumb, article, t]);
  return (
    <ArticleUpsertForm
      isLoading={loading}
      article={article!}
      isSubmitting={isSubmitting}
      submitButtonLabel={t("Update")}
      onChange={onArticleUpsertFormChange}
    />
  );
};

import { zodSchema } from "@health/domains";
import { z } from "zod";

export const ArticleUpsertFormSchema = z.object({
  active: z.boolean().nullable().optional(),
  title: z.string().min(3).max(255),
  category: zodSchema.articleCategoryAutocomplete,
  content: z.string().min(3).nullable().optional(),
  contentAr: z.string().min(3).nullable().optional(),
  contentImages: z.array(
    z.object({
      alias: z.string()?.optional().nullable(),
      url: z.string()?.optional().nullable(),
    })
  ),
  description: z.string().min(3).nullable().optional(),
  headerImage: z.string().nullable().optional(),
  readTimeMinutes: z.number().min(0).nullable().optional(),
  relatedArticles: z.array(zodSchema.articleAutocomplete).optional().nullable(),
  tags: z.array(z.string()).nullable().optional(),
});

export type IArticleUpsertFormValues = z.infer<typeof ArticleUpsertFormSchema>;

export const articleUpsertFormSchemaDefaultValues: Partial<IArticleUpsertFormValues> = {
  active: false,
  title: "",
  category: undefined,
  content: "",
  contentAr: "",
  contentImages: [],
  description: "",
  headerImage: "",
  readTimeMinutes: 0,
  relatedArticles: [],
  tags: [],
};

import { ArticlesAutocomplete, ArticleCategoriesAutocomplete } from "@health/autocompletes";
import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Chip, FormNumberField, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import React, { FC, Fragment } from "react";
import { useFormContext } from "react-hook-form";

export const ArticleInformationForm: FC = () => {
  const { t } = useTranslation("gcadmin");
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <FormTextField name={"title"} label={t("Title")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <ArticleCategoriesAutocomplete name={"category"} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <FormNumberField name={"readTimeMinutes"} label={t("Read Time (Minutes)")} />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <AutocompleteController
          ControllerProps={{
            control: control,
            name: "tags",
          }}
          TextFieldProps={{
            placeholder: t("Tags"),
            error: Boolean(errors?.type?.message),
            helperText: errors?.type?.message,
            rows: 3,
          }}
          multiple
          freeSolo
          renderTags={(value, getTagProps) =>
            value?.map((option, index) => (
              <Fragment key={index}>
                <Chip variant='outlined' sx={{ height: "23px" }} label={option} {...getTagProps({ index })} />
              </Fragment>
            ))
          }
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <ArticlesAutocomplete label={t("Related Articles")} name={"relatedArticles"} multiple />
      </Grid>
      <Grid item {...formGirdBreakPoints} xs={12}>
        <FormSwitch name='active' label={t("Is Active")} />
      </Grid>
    </Grid>
  );
};

/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HealthSymptomFragmentFragmentDoc } from '../../fragments/__generated__/healthSymptomData';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthSymptomSpecializationDeleteMutationVariables = Types.Exact<{
  healthSymptomSpecializationDeleteId: Types.Scalars['ID'];
}>;


export type HealthSymptomSpecializationDeleteMutation = { __typename?: 'Mutation', healthSymptomSpecializationDelete?: { __typename?: 'HealthSymptomSpecializationDelete', healthSymptomSpecialization?: { __typename?: 'HealthSymptomSpecialization', id: string, code?: string | null, healthSymptom?: { __typename?: 'HealthSymptom', id: string, name: string, created: any, specializations?: Array<{ __typename?: 'HealthSymptomSpecialization', id: string, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null> | null } | null } | null, specializationErrors: Array<{ __typename?: 'SpecializationError', code: Types.SpecializationErrorCode, field?: string | null, message?: string | null }> } | null };


export const HealthSymptomSpecializationDeleteDocument = gql`
    mutation HealthSymptomSpecializationDelete($healthSymptomSpecializationDeleteId: ID!) {
  healthSymptomSpecializationDelete(id: $healthSymptomSpecializationDeleteId) {
    healthSymptomSpecialization {
      id
      code
      healthSymptom {
        ...HealthSymptomFragment
      }
    }
    specializationErrors {
      code
      field
      message
    }
  }
}
    ${HealthSymptomFragmentFragmentDoc}`;
export type HealthSymptomSpecializationDeleteMutationFn = Apollo.MutationFunction<HealthSymptomSpecializationDeleteMutation, HealthSymptomSpecializationDeleteMutationVariables>;

/**
 * __useHealthSymptomSpecializationDeleteMutation__
 *
 * To run a mutation, you first call `useHealthSymptomSpecializationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthSymptomSpecializationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthSymptomSpecializationDeleteMutation, { data, loading, error }] = useHealthSymptomSpecializationDeleteMutation({
 *   variables: {
 *      healthSymptomSpecializationDeleteId: // value for 'healthSymptomSpecializationDeleteId'
 *   },
 * });
 */
export function useHealthSymptomSpecializationDeleteMutation(baseOptions?: Apollo.MutationHookOptions<HealthSymptomSpecializationDeleteMutation, HealthSymptomSpecializationDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthSymptomSpecializationDeleteMutation, HealthSymptomSpecializationDeleteMutationVariables>(HealthSymptomSpecializationDeleteDocument, options);
      }
export type HealthSymptomSpecializationDeleteMutationHookResult = ReturnType<typeof useHealthSymptomSpecializationDeleteMutation>;
export type HealthSymptomSpecializationDeleteMutationResult = Apollo.MutationResult<HealthSymptomSpecializationDeleteMutation>;
export type HealthSymptomSpecializationDeleteMutationOptions = Apollo.BaseMutationOptions<HealthSymptomSpecializationDeleteMutation, HealthSymptomSpecializationDeleteMutationVariables>;
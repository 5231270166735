/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticlesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.ArticleFilterInput>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ArticlesQuery = { __typename?: 'Query', articles?: { __typename: 'ArticleCountableConnection', pageInfo: { __typename: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename: 'ArticleCountableEdge', node: { __typename: 'Article', active?: boolean | null, content?: string | null, contentAr?: string | null, createdDate: any, description?: string | null, headerImage?: string | null, id: string, readTimeMinutes?: number | null, tags?: Array<string | null> | null, title?: string | null, category?: { __typename: 'ArticleCategory', code?: string | null, display?: string | null, displayAr?: string | null, id: string } | null, contentImages?: Array<{ __typename: 'ArticleContentImage', alias: string, id: string, url: string } | null> | null, relatedArticles?: Array<{ __typename: 'Article', title?: string | null, id: string } | null> | null } }> } | null };


export const ArticlesDocument = gql`
    query Articles($after: String, $filter: ArticleFilterInput, $before: String, $first: Int, $last: Int) {
  articles(
    after: $after
    filter: $filter
    before: $before
    first: $first
    last: $last
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
      __typename
    }
    edges {
      node {
        active
        content
        contentAr
        category {
          code
          display
          displayAr
          id
          __typename
        }
        contentImages {
          alias
          id
          url
          __typename
        }
        createdDate
        description
        headerImage
        id
        readTimeMinutes
        relatedArticles {
          title
          id
          __typename
        }
        tags
        title
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useArticlesQuery__
 *
 * To run a query within a React component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useArticlesQuery(baseOptions?: Apollo.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
      }
export function useArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
        }
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesQueryResult = Apollo.QueryResult<ArticlesQuery, ArticlesQueryVariables>;
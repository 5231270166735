import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormNumberField, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { HealthConditionsAutocomplete, RiskFactorTemplatesAutocomplete } from "@health/autocompletes";
import { guidedCareTypeOptions } from "@health/enum-options";

type GuidedCareTemplateInformationFormProps = {
  isUpdateMode: boolean;
};

export const GuidedCareTemplateInformationForm: FC<GuidedCareTemplateInformationFormProps> = props => {
  const { isUpdateMode } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"name"} label={t("Name")} disabled={isUpdateMode} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <HealthConditionsAutocomplete name={"healthCondition"} filter={{ isActive: true }} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormAutocomplete name={"guidedCareType"} label={t("Type")} options={guidedCareTypeOptions} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <RiskFactorTemplatesAutocomplete name={"riskFactorTemplate"} filter={{ isActive: true }} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormNumberField name={"templateDuration"} label={t("Duration (Days)")} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormSwitch name={"isRenewable"} label={t("Is Renewable")} />
      </Grid>
    </Grid>
  );
};

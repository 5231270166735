/* eslint-disable react/prop-types */
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { FC, useState } from "react";
import { ArticleUpdateMutation, useArticleUpdateMutation } from "../gql";
import { useTranslation } from "@toolkit/i18n";

interface ArticleActivationButtonProps {
  articleId: string;
  isActive: boolean;
}
export const ArticleActivationButton: FC<ArticleActivationButtonProps> = ({ articleId, isActive }) => {
  const { t } = useTranslation("gcadmin");
  const [checked, setChecked] = useState(isActive);
  const { succeeded, failed } = useAddToast();

  const [updateArticle, { loading }] = useArticleUpdateMutation({
    onCompleted: (data: ArticleUpdateMutation) => {
      setChecked(data?.articleUpdate?.active!);
      succeeded(t("Article updated successfully"));
    },
    onError: () => {
      failed(t("Failed in updating article"));
    },
  });

  const handleChangeActivity = event => {
    const isChecked: boolean = event.target.checked;
    updateArticle({
      variables: {
        articleUpdateId: articleId,
        input: {
          active: isChecked,
        },
      },
    });
  };
  return <CustomToggleButton checked={checked} onChange={handleChangeActivity} isLoading={loading} />;
};

import { z } from "zod";
import { createZodAutocompleteObject } from "@toolkit/ui";
import { zodSchema } from "@health/domains";
import { GuidedCareProgramActivityServiceUpsertFormSchema } from "../ProgramActivityServiceUpsert/GuidedCareProgramActivityServiceUpsertFormSchema";
import { GuidedCareProgramMemberUpsertFormSchema } from "../ProgramMemberUpsert/GuidedCareProgramMemberUpsertFormSchema";

export const GuidedCareProgramActivityUpsertFormSchema = z.object({
  templateActivityType: zodSchema.guidedCareActivityTypeAutocompleteEnum,
  teamMember: createZodAutocompleteObject(GuidedCareProgramMemberUpsertFormSchema),
  numberOfOccurrences: z.coerce.number().min(1),
  frequencyType: zodSchema.appointmentFrequencyTypeAutocompleteEnum,
  activityServices: z.array(GuidedCareProgramActivityServiceUpsertFormSchema).optional(),
  beforeActivity: zodSchema.medicalMessageAutocompleteOptional,
  beforeActivityOffset: z.coerce
    .number()
    .min(0)
    .optional()
    .transform(val => val || null),
  afterActivity: zodSchema.medicalMessageAutocompleteOptional,
  afterActivityOffset: z.coerce
    .number()
    .min(0)
    .optional()
    .transform(val => val || null),
  activityId: z.string().optional(),
  templateActivityId: z.string().optional(),
});

export type IGuidedCareProgramActivityUpsertFormValues = z.infer<typeof GuidedCareProgramActivityUpsertFormSchema>;

export const guidedCareProgramActivityUpsertFormDefaultValues: Partial<IGuidedCareProgramActivityUpsertFormValues> = {
  templateActivityType: undefined,
  frequencyType: undefined,
  numberOfOccurrences: undefined,
  activityServices: [],
  beforeActivity: null,
  beforeActivityOffset: undefined,
  afterActivity: null,
  afterActivityOffset: undefined,
  teamMember: undefined,
  templateActivityId: undefined,
};

/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleQueryVariables = Types.Exact<{
  articleId: Types.Scalars['ID'];
}>;


export type ArticleQuery = { __typename?: 'Query', article?: { __typename?: 'Article', active?: boolean | null, content?: string | null, contentAr?: string | null, createdDate: any, description?: string | null, headerImage?: string | null, id: string, readTimeMinutes?: number | null, tags?: Array<string | null> | null, title?: string | null, category?: { __typename?: 'ArticleCategory', code?: string | null, display?: string | null, displayAr?: string | null, id: string } | null, contentImages?: Array<{ __typename?: 'ArticleContentImage', alias: string, id: string, url: string } | null> | null, relatedArticles?: Array<{ __typename?: 'Article', id: string, title?: string | null } | null> | null } | null };


export const ArticleDocument = gql`
    query Article($articleId: ID!) {
  article(id: $articleId) {
    active
    content
    contentAr
    category {
      code
      display
      displayAr
      id
    }
    contentImages {
      alias
      id
      url
    }
    createdDate
    description
    headerImage
    id
    readTimeMinutes
    relatedArticles {
      id
      title
    }
    tags
    title
  }
}
    `;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useArticleQuery(baseOptions: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
      }
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;
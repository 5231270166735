import { GuidedCareActivityType } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, Grid, useModal } from "@toolkit/ui";
import { FC } from "react";
import { GuidedCareProgramActivityServicesModal } from "../../modals/ProgramActivityServices/GuidedCareProgramActivityServicesModal";

type GuidedCareProgramActivityServicesManageProps = {
  activityType: GuidedCareActivityType;
  activityIndex: number;
  isReadOnly?: boolean;
};

export const GuidedCareProgramActivityServicesManage: FC<GuidedCareProgramActivityServicesManageProps> = props => {
  const { activityType, activityIndex, isReadOnly } = props;

  const { t } = useTranslation("gcadmin");
  const { open, handleOpen, handleClose } = useModal();

  return (
    <Grid item xs={12}>
      <Button
        variant={"contained"}
        onClick={handleOpen}
        disabled={activityType !== GuidedCareActivityType.Lab && activityType !== GuidedCareActivityType.Rad}
      >
        {isReadOnly ? t("View") : t("Manage")}
      </Button>

      {open && (
        <GuidedCareProgramActivityServicesModal isOpen={open} activityIndex={activityIndex} onClose={handleClose} isReadOnly={isReadOnly} />
      )}
    </Grid>
  );
};

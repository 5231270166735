import { useTranslation } from "@toolkit/i18n";
import { Box, CustomDialog, IconButton, MuiMoreVertIcon, TableGrid } from "@toolkit/ui";
import { IGuidedCareTemplateAllActivitiesItemsContainerParams } from "pages/Templates/types";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { GuidedCareTemplateActivitiesItemList } from "../TemplateActivitiesItemList/GuidedCareTemplateActivitiesItemList";
import { useGuidedCareTemplateActivitiesItemsDialogStyle } from "./useGuidedCareTemplateActivitiesItemsDialogStyle";

type GuidedCareTemplateActivitiesItemsDialogProps = {
  maxOffsetInDays: number;
  minOffsetInDays: number;
};

export const GuidedCareTemplateActivitiesItemsDialog: FC<GuidedCareTemplateActivitiesItemsDialogProps> = props => {
  const { maxOffsetInDays, minOffsetInDays } = props;

  const { t } = useTranslation("gcadmin");

  const { templateId } = useParams<IGuidedCareTemplateAllActivitiesItemsContainerParams>();

  const { classes } = useGuidedCareTemplateActivitiesItemsDialogStyle();

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <CustomDialog
      type='base'
      open={open}
      maxWidth={"md"}
      title={t("Activity Items")}
      DialogContentProps={{
        sx: { maxHeight: "100vh" },
      }}
      DialogActionsProps={{
        hasClose: true,
        onClose: handleToggle,
      }}
      DialogTitleProps={{
        title: t("Activity Items"),
        onClose: handleToggle,
      }}
      button={
        <IconButton className={classes.showMoreButtonIcon} onClick={handleToggle}>
          <Box mt={1} className={classes.moreIcon}>
            <MuiMoreVertIcon />
          </Box>
        </IconButton>
      }
    >
      <GuidedCareTemplateActivitiesItemList templateId={templateId!} minOffsetInDays={minOffsetInDays} maxOffsetInDays={maxOffsetInDays}>
        <TableGrid />
      </GuidedCareTemplateActivitiesItemList>
    </CustomDialog>
  );
};

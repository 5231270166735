import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { TFunction } from "@toolkit/i18n";
import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import {
  GuidedCareProgramActivityItemListContainer,
  GuidedCareProgramCreateContainer,
  GuidedCareProgramListContainer,
  GuidedCareProgramTeamListContainer,
  GuidedCareProgramUpdateContainer,
} from "../containers";
import { guidedCareProgramsPaths, guidedCareProgramsRoute } from "./GuidedCareProgramsPaths";

export const guidedCareProgramsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "guided-care-programs-routes",
    text: t("Programs", { ns: "gcadmin" }),
    route: guidedCareProgramsRoute,
    isProhibited: !hasPermission(PermissionEnum.ManagePrograms),
    subItems: [
      {
        id: "guided-care-program-list-route",
        route: guidedCareProgramsPaths.list.route,
        fullPath: guidedCareProgramsPaths.list.fullPath,
        element: <GuidedCareProgramListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-program-create-route",
        route: guidedCareProgramsPaths.create.route,
        fullPath: guidedCareProgramsPaths.create.fullPath,
        hidden: true,
        element: <GuidedCareProgramCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-program-update-route",
        route: guidedCareProgramsPaths.update.route,
        fullPath: guidedCareProgramsPaths.update.fullPath,
        hidden: true,
        element: <GuidedCareProgramUpdateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-program-team-list-route",
        route: guidedCareProgramsPaths.teamList.route,
        fullPath: guidedCareProgramsPaths.teamList.fullPath,
        hidden: true,
        element: <GuidedCareProgramTeamListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-program-activity-item-list-route",
        route: guidedCareProgramsPaths.activityItemList.route,
        fullPath: guidedCareProgramsPaths.activityItemList.fullPath,
        hidden: true,
        element: <GuidedCareProgramActivityItemListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};

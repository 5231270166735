import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { articlesBreadcrumb } from "../../constants/ArticlesBreadcrumbs";
import { useArticlesQuery } from "../../gql/queries";
import { useArticleCategoriesListTableColumns } from "./useArticlesListTableColumns";
import { ArticlesQueryNode } from "../../types";
import { articlesPaths } from "../../constants/ArticlesPaths";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "@health/domains";

export const ArticleCategoriesList: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  const onEditRowClick = (item: ArticlesQueryNode) => {
    navigate(articlesPaths.update.fullPathWithParams({ articleId: item?.id! }));
  };
  const onAddNewItemClick = () => {
    navigate(articlesPaths.new.fullPath);
  };
  useEffect(() => {
    setBreadCrumb({
      title: articlesBreadcrumb().title,
    });
  }, []);

  return (
    <GridProvider
      gridName='articles'
      query={useArticlesQuery}
      columns={useArticleCategoriesListTableColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};

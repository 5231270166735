import { createPathDefinition } from "@toolkit/core";
import { knowledgeBaseRoute } from "pages/KnowledgeBase/constants/knowledgeBasePaths";

export const articlesRoute = `${knowledgeBaseRoute}/articles`;

export const articlesPaths = createPathDefinition(articlesRoute, {
  main: "",
  new: "new",
  update: ":articleId",
});

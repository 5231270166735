import { guidedCareActivityTypeOptionsMap, guidedCareTeamMemberPositionOptionsMap } from "@health/enum-options";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTable, FormActions, FormCard, Grid, PageWrapper, Typography } from "@toolkit/ui";
import { convertTemplateActivityItemsToFormValues } from "pages/Templates/others";
import {
  IGuidedCareTemplateInterventionActivityItemsFormEvent,
  IGuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalEvent,
} from "pages/Templates/types";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useGuidedCareTemplateInterventionActivityItemsColumns } from "./useGuidedCareTemplateInterventionActivityItemsColumns";
import { useGuidedCareTemplateInterventionActivityItemsFormStyle } from "./useGuidedCareTemplateInterventionActivityItemsFormStyle";
import {
  guidedCareTemplateInterventionActivityItemsFormSchema,
  IGuidedCareTemplateInterventionActivityItemsFormValues,
  guidedCareTemplateInterventionActivityItemsFormDefaultValues,
} from "./GuidedCareTemplateInterventionActivityItemsFormSchema";
import {
  GuidedCareTemplateInterventionActivityItemUpdateModal,
  GuidedCareTemplateInterventionActivityItemUpdateModalApi,
} from "../../modals/TemplateInterventionActivityItemUpdate/GuidedCareTemplateInterventionActivityItemUpdateModal";
import { TemplateInterventionActivity } from "@health/queries/types";
import { IGuidedCareTemplateActivityItemUpdateFormValues } from "../TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateFormSchema";

type GuidedCareTemplateInterventionActivityItemsFormProps = {
  isLoading?: boolean;
  activity: TemplateInterventionActivity;
  onChange: (event: IGuidedCareTemplateInterventionActivityItemsFormEvent) => void;
};

export const GuidedCareTemplateInterventionActivityItemsForm: FC<GuidedCareTemplateInterventionActivityItemsFormProps> = props => {
  const { isLoading, activity, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const { classes } = useGuidedCareTemplateInterventionActivityItemsFormStyle();

  const form = useCustomForm<IGuidedCareTemplateInterventionActivityItemsFormValues>({
    defaultValues: guidedCareTemplateInterventionActivityItemsFormDefaultValues,
    schema: guidedCareTemplateInterventionActivityItemsFormSchema,
  });

  const { watch, setValue, handleSubmit } = form;

  const activityItems = watch("activityItems");

  const onSubmit = (values: IGuidedCareTemplateInterventionActivityItemsFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const onGuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalChange = (
    event: IGuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalEvent
  ) => {
    if (event.type === "UPDATE") {
      const updatedActivityItems = activityItems?.map(item => {
        if (item?.activityItemId === event.payload.values.activityItemId) {
          return event.payload.values;
        } else {
          return item;
        }
      });

      setValue("activityItems", updatedActivityItems);

      GuidedCareTemplateInterventionActivityItemUpdateModalApi.close();
    }
  };

  const onEditRowClick = (item: IGuidedCareTemplateActivityItemUpdateFormValues) => {
    GuidedCareTemplateInterventionActivityItemUpdateModalApi.open({ activityItem: item });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (activity?.interventionActivityEventItems) {
      const _activityItems = convertTemplateActivityItemsToFormValues(activity?.interventionActivityEventItems!);

      setValue("activityItems", _activityItems);
    }
  }, [activity?.interventionActivityEventItems, setValue]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions hasCancel hasFormButton formButtonTitle={t("Save")} onNavigation={handleCancel} newButtonDisabled={isLoading} />
          }
        >
          <FormCard title={t("Activity Items")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              <GuidedCareTemplateInterventionActivityItemUpdateModal
                onChange={onGuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalChange}
              />

              <Grid item xs={12}>
                <Typography className={classes.subtitle}>
                  {t("Team Member")}: {t(guidedCareTeamMemberPositionOptionsMap[activity?.templateTeamMember?.position!]?.label!)}
                </Typography>

                <Typography className={classes.subtitle}>
                  {t("Activity Type")}: {t(guidedCareActivityTypeOptionsMap[activity?.templateActivityType!]?.label)}
                </Typography>

                {activity?.medicalMessage?.name && (
                  <Typography className={classes.subtitle}>
                    {t("Medical Message")}: {activity?.medicalMessage?.name}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <CustomTable
                  data={activityItems}
                  columns={useGuidedCareTemplateInterventionActivityItemsColumns()}
                  isEditVisible
                  isRowEditable
                  scrollable
                  hasFooter={false}
                  onEditRow={onEditRowClick}
                />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};

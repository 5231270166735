/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleCategoriesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.ArticleCategoryFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ArticleCategoriesQuery = { __typename?: 'Query', articleCategories?: { __typename?: 'ArticleCategoryCountableConnection', edges: Array<{ __typename?: 'ArticleCategoryCountableEdge', node: { __typename?: 'ArticleCategory', display?: string | null, displayAr?: string | null, code?: string | null, id: string } }>, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const ArticleCategoriesDocument = gql`
    query ArticleCategories($after: String, $before: String, $filter: ArticleCategoryFilterInput, $first: Int, $last: Int) {
  articleCategories(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
  ) {
    edges {
      node {
        display
        displayAr
        code
        id
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useArticleCategoriesQuery__
 *
 * To run a query within a React component, call `useArticleCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useArticleCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ArticleCategoriesQuery, ArticleCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleCategoriesQuery, ArticleCategoriesQueryVariables>(ArticleCategoriesDocument, options);
      }
export function useArticleCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleCategoriesQuery, ArticleCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleCategoriesQuery, ArticleCategoriesQueryVariables>(ArticleCategoriesDocument, options);
        }
export type ArticleCategoriesQueryHookResult = ReturnType<typeof useArticleCategoriesQuery>;
export type ArticleCategoriesLazyQueryHookResult = ReturnType<typeof useArticleCategoriesLazyQuery>;
export type ArticleCategoriesQueryResult = Apollo.QueryResult<ArticleCategoriesQuery, ArticleCategoriesQueryVariables>;
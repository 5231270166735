import { createPathDefinition } from "@toolkit/core";
import { knowledgeBaseRoute } from "pages/KnowledgeBase/constants/knowledgeBasePaths";

export const articlesCategoriesRoute = `${knowledgeBaseRoute}/article-categories`;

export const articlesCategoriesPaths = createPathDefinition(articlesCategoriesRoute, {
  main: "",
  new: "new",
  update: ":articleCategoryId",
});

import { useTranslation } from "@toolkit/i18n";
import { Grid, MDEditorField } from "@toolkit/ui";
import { ArticleQueryData } from "pages/KnowledgeBaseArticles/types";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

type ArticleFormDescriptionsProps = {
  article?: ArticleQueryData;
};

export const ArticleFormDescriptions: FC<ArticleFormDescriptionsProps> = ({ article }) => {
  const { control } = useFormContext();
  const { accessToken } = useOidcUserProfile();

  const { t } = useTranslation("provider");
  return (
    <>
      <Grid item xs={12}>
        <MDEditorField
          controllerProps={{
            name: "description",
            control: control,
          }}
          label={t("Description")}
          placeholder={t("Description")}
          defaultValue={article?.description || ""}
          token={accessToken}
        />
      </Grid>
    </>
  );
};
